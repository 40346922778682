//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";

export default {
  name: "NavLogo",
  props: {
    accounts: {
      type: Array,
      required: true,
    }
  },
  computed: {
    ...mapState('user', ['user', 'currentLogin']),
  },
  data: () => {
    return {
      firm: {
        logo: null,
        name: null,
      },
      showIcon: false,
    }
  },
  created() {
    this.getFirmDetails();
  },
  methods: {
    getFirmDetails() {
      this.firm.logo = this.user.current_account.firm.logo
      this.firm.name = this.user.current_account.firm.name
    },
  }
}
