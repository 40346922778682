var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user.current_account.firm.regulatory_text ? _c('div', {
    staticClass: "text-center max-w-2xl mx-auto py-12"
  }, [_c('ui-text', {
    staticClass: "text-p-gray-4",
    attrs: {
      "size": "small"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.user.current_account.firm.regulatory_text) + "\n  ")])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }