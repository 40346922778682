var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nuxt-link', {
    staticClass: "px-6 py-3 flex flex-row hover:bg-p-navy-dark hover:bg-branding-sidebar-hover text-sm items-center",
    class: _vm.classes,
    attrs: {
      "to": _vm.to
    }
  }, [_c('ui-icon-' + _vm.icon.toLowerCase(), {
    tag: "component",
    staticClass: "w-6 h-6 mr-4",
    class: _vm.iconClasses
  }), _vm._v(" "), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }