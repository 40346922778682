import { render, staticRenderFns } from "./client.vue?vue&type=template&id=410f61d8&"
import script from "./client.vue?vue&type=script&lang=js&"
export * from "./client.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BrandingColoursCSS: require('/vercel/path0/components/BrandingColoursCSS.vue').default,NavLogo: require('/vercel/path0/components/NavLogo.vue').default,AccountSwitcher: require('/vercel/path0/components/AccountSwitcher.vue').default,Navigation: require('/vercel/path0/components/Navigation.vue').default,Avatar: require('/vercel/path0/components/Avatar.vue').default,Navigation: require('/vercel/path0/components/Navigation.vue').default,NotificationBell: require('/vercel/path0/components/NotificationBell.vue').default,Notification: require('/vercel/path0/components/Notification.vue').default,RegText: require('/vercel/path0/components/RegText.vue').default,NotificationPopup: require('/vercel/path0/components/NotificationPopup.vue').default,FileUploader: require('/vercel/path0/components/FileUploader.vue').default})
