//
//
//
//
//
//
//
//
//
//
//
//
//

import timeEntries from "@/api/timeEntries";
import {mapState} from "vuex";

export default {
  name: "RecentTimerEntries",
  props: {
    runningTimer: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  data() {
    return {
      timers: [],
      meta: {},
      currentTimer: '',
    }
  },

  computed: {
    ...mapState('user', ['user']),

  },

  created() {
    this.grabTimers();
    this.$nuxt.$on('timerStarted', this.grabTimers);
    this.$nuxt.$on('timerStopped', this.grabTimers);
  },

  beforeDestroy() {
    this.$nuxt.$off('timerStarted');
  },

  methods: {
    grabTimers() {
      timeEntries.all({params: {'filter[accounts]': this.user.current_account.uuid, sort: '-start_date', per_page: 5}})
        .then(rsp => {
          this.timers = rsp.data.data;
          this.meta = rsp.data.meta;
        })
    },
    secondsToHms(d) {
      d = Number(d);
      let h = Math.floor(d / 3600);
      let m = Math.floor(d % 3600 / 60);
      let s = Math.floor(d % 3600 % 60);

      let hDisplay = h > 0 ? h + (h == 1 ? "h, " : "h ") : "";
      let mDisplay = m > 0 ? m + (m == 1 ? "m, " : "m ") : "";
      let sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
      return hDisplay + mDisplay + sDisplay;
    }
  }
}
