var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-12"
  }, [_c('ui-title', {
    staticClass: "text-p-navy font-bold mb-4",
    attrs: {
      "size": "small"
    }
  }, [_vm._v("Recent time entries")]), _vm._v(" "), _c('div', {
    staticClass: "rounded-md mb-4"
  }, [_vm._l(_vm.timers, function (timer) {
    return _c('timer-list-entry', {
      key: timer.uuid,
      attrs: {
        "timer": timer,
        "editable": _vm.editable,
        "running-timer": _vm.runningTimer
      }
    });
  }), _vm._v(" "), _c('div', {
    staticClass: "flex flex-row justify-between mt-4"
  }, [_c('nuxt-link', {
    staticClass: "hover:text-p-pink",
    attrs: {
      "to": "/dashboard/my-logged-time"
    }
  }, [_c('ui-text', {
    attrs: {
      "size": ""
    }
  }, [_vm._v("← My logged time")])], 1), _vm._v(" "), _c('ui-text', {
    staticClass: "font-bold",
    attrs: {
      "size": ""
    }
  }, [_vm._v("Total Time: " + _vm._s(_vm.secondsToHms(_vm.meta.computed_time_elapsed_in_seconds)))])], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }