import clientAPI from "@/api/clients";

export default ({app}, inject) => {
  inject('helpers', {
    poundsToPennies(amount) {
      if (amount == null) {
        return null
      }

      if (typeof amount !== 'string' && typeof amount !== 'number') {
        throw new Error('Amount passed must be of type String or Number.')
      }
      return Math.round(100 * parseFloat(typeof amount === 'string' ? amount.replace(/[£,]/g, '') : amount))
    },

    penniesToPounds(amount) {
      if (amount == null) {
        return null
      }

      if (typeof amount !== 'string' && typeof amount !== 'number') {
        throw new Error('Amount passed must be of type String or Number.')
      }

      return (amount / 100).toFixed(2)
    },

    nicelyFormattedDate(raw) {
      return app.$dayjs(raw).format('Do MMM YYYY')
    },

    basicDate(value) {
      return app.$dayjs(value).format('DD-MM-YYYY')
    },

    formatHumanDate(value) {
      return app.$dayjs(value).format('Do MMM YYYY')
    },

    nicelyFormattedWithMonth(value) {
      return app.$dayjs(value).format('Do MMMM YYYY')
    },

    formatHumanDateTime(value) {
      return app.$dayjs(value).format('Do MMM YYYY HH:mm:ss')
    },

    formatDateTime(value) {
      return app.$dayjs(value).format('YYYY-MM-DDTHH:mm:ss.SSS')
    },

    formatDate(value) {
      if (value === null) {
        return null;
      }

      let dayjs = app.$dayjs(value);
      return dayjs.format('YYYY-MM-DD')
    },

    removeNullPropertiesInObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === '') {
          delete obj[key]
        }
      })

      return obj
    },

    formatDateForCalendar(value = null) {
      if (value === null) {
        return app.$dayjs().format('YYYY-MM-DD');
      }

      let dayjs = app.$dayjs(value);
      return dayjs.format('YYYY-MM-DD')
    },

    copyObject(destination, source) {
      return Object.keys(destination).reduce((a, key) => ({ ...a, [key]: source[key]}), {});
    },
  })
}
