var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relative flex flex-row space-x-4 items-center"
  }, [_c('notification-bell'), _vm._v(" "), _c('notification-popup'), _vm._v(" "), _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.triggerEmit.apply(null, arguments);
      }
    }
  }, [_c('ui-search-input', {
    attrs: {
      "naked": _vm.naked
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _vm._v(" "), _c('a', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Open the rocketbar',
      expression: "'Open the rocketbar'"
    }],
    staticClass: "text-p-gray-2 hover:-translate-y-1 ease-in-out duration-300 transform transition-all cursor-pointer",
    on: {
      "click": _vm.openRocketBar
    }
  }, [_c('svg', {
    staticClass: "w-6 h-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.5",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "d": "M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
    }
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }