//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import plansAPI from "~/api/plans";

export default {
  name: "ClientPlanSelect",

  props: {
    value: {
      type: String | null,
      required: true,
    },
    client_uuid: {
      type: String | null,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
    }
  },
  created() {
    this.grabPlans()
  },

  data() {
    return {
      selectedList: [],
      plans: [],
      loading: true,
      board: {
        lists: []
      },
    }
  },

  methods: {

    grabPlans() {
      if (this.client_uuid == null) {
        return;
      }
      plansAPI.allForClient(this.client_uuid)
        .then(rsp => {
          this.plans = rsp.data.data;

          if(this.value) {
            this.selectedList = this.plans.filter((item) => {
              if( Array.isArray(this.value) ) {
                return this.value.includes(item.uuid)
              } else {
                return this.value.uuid === item.uuid;
              }
            })
          }

          this.loading = false;
        })

    },

  },

  watch: {
    client_uuid(new_val){
      this.grabPlans(new_val);
    },
    selectedList(new_val) {
      if (!Array.isArray(new_val)) {
        this.$emit('input', new_val.uuid);
        return;
      }

      let reduced = new_val.map(item => item.uuid);

      this.$emit('input', reduced);
    },
  }
}
