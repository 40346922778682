var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isClient ? _c('nav', [_vm._l(_vm.clientItems, function (item, index) {
    return _c('nav-item', {
      key: index,
      attrs: {
        "to": item.to,
        "icon": item.icon
      }
    }, [_vm._v(_vm._s(item.text) + "\n    ")]);
  }), _vm._v(" "), _c('a', {
    staticClass: "px-6 py-3 flex flex-row hover:bg-p-navy-dark text-sm hover:bg-branding-sidebar-hover items-center text-white text-branding-sidebar-text cursor-pointer",
    attrs: {
      "href": "https://docs.plannrcrm.com/collection/110-client-portal",
      "target": "_blank"
    }
  }, [_c('ui-icon-chat', {
    staticClass: "w-6 h-6 mr-4"
  }), _vm._v(" "), _c('span', [_vm._v("Help & Support")])], 1)], 2) : _vm._e(), _vm._v(" "), _vm.isEmployee ? _c('nav', [_vm._l(_vm.navItems, function (item, index) {
    return _c('nav-section', {
      key: index,
      attrs: {
        "section": item
      }
    });
  }), _vm._v(" "), _c('a', {
    staticClass: "px-6 py-3 flex flex-row hover:bg-p-navy-dark text-sm hover:bg-branding-sidebar-hover items-center text-white text-branding-sidebar-text cursor-pointer",
    attrs: {
      "href": "https://docs.plannrcrm.com/collection/97-financial-professionals",
      "target": "_blank"
    }
  }, [_c('ui-icon-chat', {
    staticClass: "w-6 h-6 mr-4"
  }), _vm._v(" "), _c('span', [_vm._v("Help & Support")])], 1), _vm._v(" "), _c('a', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'New in Plannr',
      expression: "'New in Plannr'"
    }],
    staticClass: "px-6 py-3 flex flex-row hover:bg-p-navy-dark text-sm hover:bg-branding-sidebar-hover items-center text-white text-branding-sidebar-text cursor-pointer",
    attrs: {
      "data-canny-changelog": ""
    }
  }, [_c('ui-icon-heart', {
    staticClass: "w-6 h-6 mr-4"
  }), _vm._v(" "), _c('span', [_vm._v("Updates")])], 1)], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }