var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full"
  }, [_c('textarea', {
    ref: _vm.setRef,
    staticClass: "editor prose",
    class: _vm.setRef
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }