//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {Timer} from "easytimer.js";
import timeEntryAPI from "@/api/timeEntries";

export default {
  name: "list-entry",
  props: {
    timer: {
      type: Object,
      required: true,
    },
    runningTimer: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  data() {
    return {
      currentTimer: '0',
      timerHolder: null,
    }
  },
  mounted(){
    if( this.runningTimer !== null ) {
      if (this.runningTimer.uuid === this.timer.uuid) {
        this.startLocalTimer(this.runningTimer);
      }
    }
    this.$nuxt.$on('timerStopped', this.stopTimer);
  },
  watch: {
    runningTimer(new_val){
      if(new_val !== null)
      {
        if(new_val.uuid === this.timer.uuid){
          this.startLocalTimer(new_val);
        }
      }
    }
  },

  beforeDestroy() {
    if( this.timerHolder !== null ) {
      this.timerHolder.removeEventListener('secondsUpdated');
    }
    this.$nuxt.$off('timerStopped');
  },

  computed: {
    trackableLink() {
      let route = null;
      switch (this.timer.trackable_type) {
        case 'firm':
          route = '/business'
          break;
        case 'client':
          route = '/clients/' + this.timer.trackable.uuid;
          break;
        case 'case':
          route = '/case/' + this.timer.trackable.uuid;
          break;
        case 'board_task':
          route = '/'
      }
      return route;

    }
  },
  methods: {
    secondsToHms(d) {
      d = Number(d);
      let h = Math.floor(d / 3600);
      let m = Math.floor(d % 3600 / 60);
      let s = Math.floor(d % 3600 % 60);

      let hDisplay = h > 0 ? h + (h == 1 ? "h, " : "h ") : "";
      let mDisplay = m > 0 ? m + (m == 1 ? "m, " : "m ") : "";
      let sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
      return hDisplay + mDisplay + sDisplay;
    },

    startLocalTimer(timer) {
      this.timerHolder = new Timer();
      this.timerHolder.start({
        precision: 'seconds',
        startValues: {
          seconds: this.$dayjs(new Date).diff(timer.start_date, 'seconds')
        }
      })
      this.timerHolder.addEventListener('secondsUpdated', (e) => {
        this.currentTimer = this.timerHolder.getTimeValues().toString()
      })

    },

    stopTimer() {
      if(this.timerHolder !== null){
        this.timerHolder.stop();
        this.timerHolder.removeEventListener('secondsUpdated');
      }
      this.currentTimer = null;
      this.$nuxt.$authService.refreshState();
      this.$nuxt.$emit('notification', 'Timer stopped');
    },

    remove(timer){
      this.$confirm({
        button: {
          no: 'No',
          yes: 'Yes'
        },
        message: 'Are you sure you would like to remove this timer record?',
        callback: confirm => {
          if (confirm) {
            timeEntryAPI.remove(timer.uuid)
              .then(rsp => {
                this.$nuxt.$emit('notification', 'Timer deleted',);
                this.$emit('refresh');
              })
          }
        }
      })
    },

    edit(){
      this.$emit('click');
    }

  }
}
