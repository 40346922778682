import { render, staticRenderFns } from "./simplified.vue?vue&type=template&id=b6af8a42&"
import script from "./simplified.vue?vue&type=script&lang=js&"
export * from "./simplified.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNotificationEventListener: require('/vercel/path0/components/AppNotificationEventListener.vue').default,BrandingColoursCSS: require('/vercel/path0/components/BrandingColoursCSS.vue').default,AccountSwitcher: require('/vercel/path0/components/AccountSwitcher.vue').default,Navigation: require('/vercel/path0/components/Navigation.vue').default,Avatar: require('/vercel/path0/components/Avatar.vue').default,Navigation: require('/vercel/path0/components/Navigation.vue').default,HeaderSearchPanel: require('/vercel/path0/components/HeaderSearchPanel.vue').default,SearchBlock: require('/vercel/path0/components/SearchBlock.vue').default,Notification: require('/vercel/path0/components/Notification.vue').default,FileUploader: require('/vercel/path0/components/FileUploader.vue').default,RocketBar: require('/vercel/path0/components/RocketBar.vue').default,ModalsCreateNote: require('/vercel/path0/components/modals/create-note.vue').default,ModalsCreateTaskFromAnywhere: require('/vercel/path0/components/modals/create-task-from-anywhere.vue').default})
