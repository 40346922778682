import incomeReconciliationAPI from "../api/incomeReconciliation"

export default {
  namespace: true,

  state: () => ({
    currentStatement: {
      start_date: null,
      end_date: null,
      uuid: null
    },
    loaded: false,
  }),

  mutations: {
    SET_STATEMENT(state, statement) {
      state.currentStatement = statement
    },
    SET_LOADED(state) {
      state.loaded = true
    }
  },

  getters: {
    currentStatementUuid: state => {
      return state.currentStatement?.uuid
    },
    statementStart: state => {
      return state.currentStatement?.start_date
    },
    statementEnd: state => {
      return state.currentStatement?.end_date
    }
  },

  actions: {
    getStatement({commit}, uuid) {
      return incomeReconciliationAPI.getStatement(uuid)
        .then(rsp => {
          commit('SET_STATEMENT', rsp.data.data)
          commit('SET_LOADED');
        })
        .catch(err => {
          throw err
        })
    }
  }
}
