var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('VMenu', {
    staticClass: "cursor-pointer",
    attrs: {
      "triggers": ['click'],
      "placement": "right"
    },
    scopedSlots: _vm._u([{
      key: "popper",
      fn: function () {
        return [_c('ui-title', {
          staticClass: "p-4 font-bold text-p-gray-5",
          attrs: {
            "size": "x-small"
          }
        }, [_vm._v("Account switcher")]), _vm._v(" "), _vm._l(_vm.accounts, function (account) {
          return _c('div', {
            key: account.uuid,
            staticClass: "p-4 hover:bg-p-gray-8 cursor-pointer",
            class: {
              'bg-p-green-pale': _vm.currentAccount.uuid === account.account.uuid
            },
            on: {
              "click": function ($event) {
                return _vm.switchTo(account);
              }
            }
          }, [_vm.currentAccount.uuid === account.account.uuid ? _c('ui-text', {
            attrs: {
              "size": "x-small"
            }
          }, [_vm._v("Currently viewing")]) : _vm._e(), _vm._v(" "), _c('ui-text', {
            staticClass: "font-bold",
            attrs: {
              "size": "small"
            }
          }, [_vm._v(_vm._s(account.account.name))]), _vm._v(" "), _c('ui-text', {
            attrs: {
              "size": "small"
            }
          }, [_vm._v(_vm._s(_vm.getRole(account.account)) + " in " + _vm._s(account.firm.name))])], 1);
        })];
      },
      proxy: true
    }])
  }, [_c('NavLogo', {
    attrs: {
      "accounts": _vm.accounts
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }