//
//
//
//
//
//
//
//

import {mapState, mapGetters} from 'vuex';

export default {
  name: "RegText",
  computed: {
    ...mapState('user', ['user']),
  }
}
