var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.popupVisible ? _c('div', {
    staticClass: "fixed top-0 left-0 bg-p-navy bg-opacity-10 cursor-pointer w-screen h-screen z-40",
    on: {
      "click": _vm.showPopup
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    ref: "notification_popup",
    staticClass: "absolute top-20 w-96 shadow-lg rounded-xl z-50"
  }, [_c('ui-well', [_c('div', {
    staticClass: "pop-over-title flex flex-row items-center justify-between"
  }, [_c('ui-title', {
    staticClass: "pr-4",
    attrs: {
      "size": "x-small"
    }
  }, [_vm._v("Notifications")]), _vm._v(" "), _c('a', {
    staticClass: "text-p-navy hover:underline cursor-pointer",
    on: {
      "click": _vm.markAllAsRead
    }
  }, [_vm.notifications.length > 0 ? _c('ui-text', {
    attrs: {
      "type": "naked",
      "size": "x-small"
    }
  }, [_vm._v("\n            Mark all as read\n          ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "pop-over-content max-h-96 overflow-y-auto mt-4"
  }, [_vm._l(_vm.notifications, function (notification) {
    return _vm.user.current_account.type !== 'client' ? _c('notifications-item', {
      key: notification.uuid,
      attrs: {
        "notification": notification
      },
      on: {
        "clicked": _vm.showPopup
      }
    }) : _vm._l(_vm.notifications, function (notification) {
      return _c('notifications-item-client', {
        key: notification.uuid,
        attrs: {
          "notification": notification
        },
        on: {
          "clicked": _vm.showPopup
        }
      });
    });
  }), _vm._v(" "), _vm.notifications.length === 0 ? _c('div', {
    staticClass: "mt-6"
  }, [_c('img', {
    attrs: {
      "src": require("assets/img/no-notifications.png"),
      "alt": "no notifications illustration"
    }
  }), _vm._v(" "), _c('ui-text', {
    staticClass: "text-p-gray-4",
    attrs: {
      "size": "small"
    }
  }, [_vm._v("Inbox zero. No notifications right now.")])], 1) : _vm._e()], 2)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }