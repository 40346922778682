var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "home"
    }
  }, [_vm.opened ? _c('div', {
    staticClass: "bg-branding-sidebar-background-opacity backdrop-filter backdrop-blur-sm transition-all ease-in-out duration-300 fixed h-full overflow-y-auto z-50 w-screen top-0 left-0"
  }, [_c('div', {
    staticClass: "max-w-7xl mx-auto"
  }, [_c('div', {
    staticClass: " p-6 text-white min-h-screen"
  }, [_c('div', {
    staticClass: "flex flex-row justify-between items-center"
  }, [_c('ui-title', {
    attrs: {
      "size": "medium"
    }
  }, [_vm._v("Search results for " + _vm._s(_vm.form.q))]), _vm._v(" "), _c('ui-icon-square-close', {
    staticClass: "h-12 w-12 cursor-pointer hover:text-p-yellow transition-all ease-in-out duration-300",
    attrs: {
      "type": "outline"
    },
    on: {
      "click": _vm.closePanel
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "my-8 overflow-y-auto flex flex-col md:flex-row"
  }, [_c('div', {
    staticClass: "md:w-1/5"
  }, [_vm.filterOptions.length ? _c('div', {
    staticClass: "bg-p-navy p-4 rounded-md"
  }, [_c('ui-text', {
    staticClass: "opacity-50 uppercase tracking-widest",
    attrs: {
      "size": "x-small"
    }
  }, [_vm._v("Filter by")]), _vm._v(" "), _c('div', {
    staticClass: "my-4"
  }, _vm._l(_vm.filterOptions, function (option) {
    return _c('div', [_c('label', {
      staticClass: "space-x-2"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.activeFilters,
        expression: "activeFilters"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": option,
        "checked": Array.isArray(_vm.activeFilters) ? _vm._i(_vm.activeFilters, option) > -1 : _vm.activeFilters
      },
      on: {
        "change": function ($event) {
          var $$a = _vm.activeFilters,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = option,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.activeFilters = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.activeFilters = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.activeFilters = $$c;
          }
        }
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "capitalize"
    }, [_vm._v(_vm._s(option))])])]);
  }), 0)], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "md:w-4/5 md:ml-8"
  }, [_c('div', {
    staticClass: "bg-white rounded-md p-4 md:p-8 divide-y divide-p-gray-7 text-p-gray-4"
  }, [_vm.filteredResultsList.length === 0 ? _c('div', [_c('ui-text', [_vm._v("Unfortunately there were no results for " + _vm._s(_vm.form.q))])], 1) : _vm._e(), _vm._v(" "), _vm._l(_vm.filteredResultsList, function (result, index) {
    return _c('search-result-item', {
      key: index,
      attrs: {
        "result": result
      },
      on: {
        "closePanel": _vm.closePanel
      }
    });
  })], 2)])])])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }