import Vue from 'vue';
import FloatingVue from 'floating-vue';

Vue.use(FloatingVue, {
  themes: {
    plannr: {
      '$resetCss': false,
      triggers: ['hover'],
      autoHide: true,
      placement: 'top',
      themes: {
        delay: {
          show: 50,
          hide: 0,
        }
      }
    },
  },
});
