//
//
//
//
//
//

import Redactor from '@/plugins/redactor/redactor.usm.min'
import handle from '@/plugins/handle.redactor'

export default {
  name: "RedactorInput",
  props: {
    model_type: {
      type: String,
      required: true,
    },
    model_uuid: {
      type: String,
      required: true,
    },
    handle_type: {
      type: String,
      required: false,
      default: 'note'
    },
    value: {
      type: String,
      required: false,
    },
    setRef:{
      type: String,
      required: false,
      default: 'editor'
    }
  },

  data: () => {
    return {
      url: '',
      redactor: null,
    }
  },

  head() {
    return {
      link: [
        {
          rel: 'stylesheet',
          href: '/handle.min.css'
        }
      ]
    }
  },
  mounted() {
    this.buildRedactor()
  },
  methods: {
    clearContents() {
      Redactor('.' + this.setRef, 'source.setCode', '<p></p>');
    },
    buildRedactor() {
      switch (this.handle_type) {
        case 'note':
          this.url = '/note/' + this.model_type + '/' + this.model_uuid + '/mentions';
          break
        case 'conversation':
          this.url = '/conversation/' + this.model_uuid + '/mentions'
          break
      }

      Redactor('.' + this.setRef, 'destroy');

      Redactor('.' + this.setRef, {
        focus: true,
        air: true,
        plugins: ['handle'],
        buttons: ['bold', 'italic', 'deleted', 'lists', 'link'],
        handle: this.$nuxt.$api.host + this.url,
        callbacks: {
          keydown: (e) => {
            let content = Redactor('.' + this.setRef, 'source.getCode')
            this.$emit('input', content);
          },
          changed: (e) => {
            let content = Redactor('.' + this.setRef, 'source.getCode')
            this.$emit('input', content);
          }
        }
      })

      if(this.value) {
        Redactor('.' + this.setRef, 'source.setCode', this.value);
      }
    }
  },
  watch: {
    handle_type(new_val) {
      this.buildRedactor()
    },
    model_type(new_val){
      this.buildRedactor()
    },
    model_uuid(new_val){
      this.buildRedactor()
    },

  }
}
