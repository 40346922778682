//
//
//
//
//
//
//
//
//

export default {
    name: "NavItem",
    props: {
        to: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        }
    },
    computed: {
        classes() {
            if (this.to === this.$route.path) {
                return 'bg-p-navy-dark border-l-2 border-p-green text-branding-sidebar-active border-branding-sidebar-active bg-branding-sidebar-hover'
            } else {
                return 'text-white text-branding-sidebar-text'
            }
        },
        iconClasses() {
            if (this.to === this.$route.path) {
                return 'text-branding-sidebar-active'
            } else {
                return 'text-p-blue-light'
            }
        }
    }
}
