var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.caseList ? _c('ui-form-field', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(_vm._s(_vm.label))];
      },
      proxy: true
    }], null, false, 2710507344)
  }, [_vm._v(" "), _c('multiselect', {
    attrs: {
      "options": _vm.caseList,
      "multiple": false,
      "loading": _vm.loading,
      "close-on-select": true,
      "clear-on-select": false,
      "preserve-search": true,
      "placeholder": "Select client cases",
      "label": "name",
      "track-by": "name"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('div', {
          staticClass: "option__desc ml-4"
        }, [_c('span', {
          staticClass: "option__title"
        }, [_vm._v(_vm._s(props.option.name))])])])];
      }
    }], null, false, 2448576664),
    model: {
      value: _vm.selectedList,
      callback: function ($$v) {
        _vm.selectedList = $$v;
      },
      expression: "selectedList"
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }