//
//
//
//
//

export default {
  name: "StyledSelect",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object | String|null,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      v: ''
    }
  },
  created(){
    this.v = this.value;
  },

  methods: {
    emitMe(e){
      this.$emit('input', e.target.value);
      this.$emit('change', e.target.value);
    }
  },
  watch:{
    value(new_value){
      this.v = new_value
    }
  }
}
