import casesAPI from '~/api/cases'
export default {
    namespaced: true,

    state: () => ({
        singleCase: {
          status: {},
          participants: []
        }
    }),

    getters: {
        getClientsFromCase(state){
            return state.singleCase.participants.filter((item) => {
                return item.type === 'client';
            })
        },
        getEmployeesFromCase(state){
            return state.singleCase.participants.filter((item) => {
                return item.type !== 'client';
            })
        }
    },

    actions: {
        setActiveCase({ commit }, singleCase) {
            commit('SET_CASE', singleCase);
        },

        refreshCurrentCase({commit}, {case_uuid}){
          casesAPI.getCase(case_uuid)
            .then(rsp => {
              commit('SET_CASE', rsp.data.data);
            })
        }
    },

    mutations: {
        SET_CASE(state, singleCase) {
            state.singleCase = singleCase;
        },
    },
}
