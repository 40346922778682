//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import notificationApi from '@/api/notifications'
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'notification-item',
  props: {
    notification: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters('user', ['isClient']),
  },
  methods: {
    redirectTo() {
      this.markAsRead(this.notification)

      this.$emit('clicked')

      if (!this.isClient) {

        if (this.notification.data.type === 'client.created') {
          this.$router.push('/clients/' + this.notification.data.model_uuid)
        }
        if (this.notification.data.model_type === 'automation_execution') {
          this.$router.push('/automations/' + this.notification.data.model_uuid)
        }

        if (this.notification.data.model_type === 'board_task') {
          if (
            this.notification.data.navigator.model_type === 'client'
          ) {
            this.$router.push(
              '/clients/' + this.notification.data.navigator.model_uuid + '/tasks'
            )
          }

          if (this.notification.data.navigator.data.model_type === 'case') {
            this.$router.push('/case/' + this.notification.data.navigator.model_uuid + '/tasks')
          }
        }

        if (this.notification.data.type === 'note.mention') {
          if (this.notification.data?.navigator?.model_type === 'client') {
            this.$router.push('/clients/' + this.notification.data?.navigator?.model_uuid + '/notes')
          }

          if (this.notification.data?.navigator?.model_type === 'case') {
            this.$router.push('/case/' + this.notification.data?.navigator?.model_uuid + '/notes')
          }

          if (this.notification.data?.navigator?.model_type === 'plan') {
            this.$router.push('/plans/' + this.notification.data?.navigator?.model_uuid + '/notes')
          }
        }

        if (this.notification.data.model_type === 'message') {
          this.$router.push('/conversations/' + this.notification?.data?.navigator?.model_uuid)
        }

        if (this.notification.data.type === 'message.mention') {

          if (this.notification.data.model_type === 'client') {
            this.$router.push('/clients/' + this.notification.data.model_uuid + '/notes')
          }

          if (this.notification.data.model_type === 'case') {
            this.$router.push('/case/' + this.notification.data.model_uuid + '/notes')
          }

          if (this.notification.data.model_type === 'plan') {
            this.$router.push('/plans/' + this.notification.data.model_uuid + '/notes')
          }

          if (this.notification.data.model_type === 'message') {
            this.$router.push('/conversations/' + this.notification.data.navigator.model_uuid)
          }

        }

        if (this.notification.data.model_type === 'conversation') {
          this.$router.push('/conversations/' + this.notification.data.model_uuid)
        }

        if (this.notification.data.model_type === 'document') {
          this.$router.push('/business/docs/' + this.notification.data.model_uuid)
        }
      }
      
    },

    markAsRead() {
      notificationApi.markAsRead(this.notification.uuid).then((rsp) => {
        this.$nuxt.$emit('NotificationRead', this.notification.uuid)
      })
      this.notification.read_at = new Date().toString()
    },
  },
}
