import employeeAPI from '@/api/employees'

export default {
  namespaced: true,

  state: () => ({
    employee: null,
  }),

  getters: {},

  actions: {
    setActiveEmployee({ commit }, employee) {
      commit('SET_EMPLOYEE', employee)
    },

    setEmployee({ commit }, employee) {
      commit('SET_EMPLOYEE', employee)
    },

    async refreshEmployee({ commit }, { uuid }) {
      await employeeAPI.getEmployee(uuid).then((rsp) => {
        commit('SET_EMPLOYEE', rsp.data.data)
      })
    },
  },

  mutations: {
    SET_EMPLOYEE(state, employee) {
      state.employee = employee
    },
  },
}
