var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.classes
  }, [_vm.section.children !== undefined ? _c('div', {
    staticClass: "transition-all ease-in-out duration-300 hover:bg-p-navy-dark hover:bg-branding-sidebar-hover"
  }, [_c('nuxt-link', {
    staticClass: "px-6 py-3 cursor-pointer border-l-0 flex flex-row text-sm items-center",
    attrs: {
      "to": _vm.section.to
    }
  }, [_c('ui-icon-' + _vm.section.icon.toLowerCase(), {
    tag: "component",
    staticClass: "w-6 h-6 mr-4",
    class: _vm.iconClasses
  }), _vm._v("\n      " + _vm._s(_vm.section.text) + "\n    ")], 1), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_vm.isExpanded ? _c('div', {
    staticClass: "flex flex-row px-6 pb-2 w-full"
  }, [_c('div', {
    staticClass: "w-6 h-6 mr-5"
  }), _vm._v(" "), _c('div', {
    staticClass: "w-full"
  }, _vm._l(_vm.section.children, function (item, index) {
    return _c('nuxt-link', {
      key: index,
      staticClass: "py-2 cursor-pointer text-white text-branding-sidebar-text hover:text-branding-sidebar-active opacity-75 flex flex-row text-sm items-center",
      class: {
        'text-branding-sidebar-active': _vm.$route.path === item.to
      },
      attrs: {
        "to": item.to
      }
    }, [_vm._v("\n            " + _vm._s(item.text) + "\n          ")]);
  }), 1)]) : _vm._e()])], 1) : _c('div', [_c('nav-item', {
    attrs: {
      "to": _vm.section.to,
      "icon": _vm.section.icon
    }
  }, [_vm._v(_vm._s(_vm.section.text) + " ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }