const ROLE_OWNER = 'owner';
const ROLE_ADMIN = 'admin';
const ROLE_MEMBER = 'member';
const ROLE_CLIENT = 'client';
const ROLE_INDIVIDUAL = 'individual';

const EMPLOYEE_ROLES = [ROLE_OWNER, ROLE_ADMIN, ROLE_MEMBER];
const SENIOR_EMPLOYEE_ROLES = [ROLE_OWNER, ROLE_ADMIN];

export default {
  state: () => ({
    user: null,
    currentAccount: null,
    currentLogin: null,
    firm: null,
    has_state: false,
  }),

  getters: {
    hasCurrentFirm(state) {
      return !!state.currentAccount;
    },
    currentFirmUuid(state) {
      return state.firm.uuid;
    },
    currentAccountUuid(state) {
      return state.currentAccount?.uuid;
    },
    currentLoginUuid(state) {
      return state.currentLogin?.uuid;
    },
    accountRole(state) {
      return state.currentAccount.role;
    },
    isOwner(state) {
      return state.currentAccount.role === ROLE_OWNER;
    },
    isAdmin(state) {
      return state.currentAccount.role === ROLE_ADMIN;
    },
    isMember(state) {
      return state.currentAccount.role === ROLE_MEMBER;
    },
    isClient(state) {
      return state.currentAccount.type === ROLE_CLIENT || state.currentAccount.type === ROLE_INDIVIDUAL;
    },
    isEmployee(state) {
      return EMPLOYEE_ROLES.includes(state.currentAccount.role);
    },
    isNotEmployee(state) {
      return !EMPLOYEE_ROLES.includes(state.currentAccount.role);
    },
    isSeniorEmployee(state) {
      return SENIOR_EMPLOYEE_ROLES.includes(state.currentAccount.role);
    },
    isNotSeniorEmployee(state) {
      return !SENIOR_EMPLOYEE_ROLES.includes(state.currentAccount.role);
    },
    getAddonSubscriptionById: (state) => (addonId) => {
      if (!state.currentAccount?.firm?.addon_subscriptions) {
        return null;
      }

      return state.currentAccount.firm.addon_subscriptions.find((subscription) => subscription.addon.id === addonId);
    },
    hasEnabledAddon: (state, getters) => (addonId) => {
      const addon = getters.getAddonSubscriptionById(addonId);

      if (!addon) {
        return false;
      }

      return addon.enabled === true;
    }
  },

  actions: {
    setUser({commit}, user) {
      commit('SET_USER', user);

      if (user.current_account) {
        commit('SET_CURRENT_ACCOUNT', user.current_account);
        commit('SET_LOGIN', user.current_login);
        commit('SET_FIRM', user.current_account.firm);
      }

      if (user.current_account && user.current_account.firm && user.current_account.firm.custom_fields) {
        $nuxt.$store.commit('customFields/SET_CUSTOM_FIELDS', user.current_account.firm.custom_fields);
      }
    },
    setBrandingColours({commit, state}, brand_colours) {
      commit('SET_FIRM_BRAND_COLOURS', brand_colours);
    },
    setLogin({commit}, login) {
        commit('SET_LOGIN', login);
    }
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_CURRENT_ACCOUNT(state, currentAccount) {
      state.currentAccount = currentAccount;
    },
    SET_LOGIN(state, currentLogin) {
      state.currentLogin = currentLogin;
    },
    SET_FIRM(state, firm) {
      state.firm = firm;
    },
    SET_FIRM_BRAND_COLOURS(state, brand_colours) {
      state.firm.brand_colours = brand_colours.brand_colours;
    },
    SET_HAS_STATE(state, has_state) {
      state.has_state = has_state;
    }
  },

  namespaced: true,
}
