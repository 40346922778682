var render = function () {
  var _vm$timer$trackable_t, _vm$timer$trackable;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.timer ? _c('div', {
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('div', {
    staticClass: "flex flex-row px-6 transition-all ease-in-out duration-300 items-center border-b border-p-gray-6 py-2",
    class: {
      'cursor-pointer hover:bg-white': _vm.editable
    }
  }, [_c('div', {
    staticClass: "flex flex-row items-center"
  }, [_c('avatar', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.timer.account.name,
      expression: "timer.account.name"
    }],
    attrs: {
      "name": _vm.timer.account.name,
      "rounded": "",
      "size": 36,
      "classes": "h-8 w-8 mr-4 overflow-hidden"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "flex flex-col"
  }, [_c('div', {
    staticClass: "flex flex-row items-center relative"
  }, [_c('ui-text', {
    staticClass: "mr-2",
    class: {
      'text-p-green-dark font-bold': _vm.timer.is_active
    },
    attrs: {
      "size": ""
    }
  }, [_vm._v(_vm._s(_vm.timer.name) + "\n          ")]), _vm._v(" "), _vm.timer.is_active ? _c('div', {
    staticClass: "animate-pulse h-3 w-3 rounded-full bg-p-green opacity-75"
  }) : _vm._e()], 1), _vm._v(" "), _c('div', [_c('ui-text', {
    staticClass: "flex flex-row space-x-1 items-center",
    attrs: {
      "size": "x-small"
    }
  }, [_c('ui-icon-link', {
    staticClass: "w-4 h-4"
  }), _vm._v(" "), _c('span', {
    staticClass: "capitalize"
  }, [_vm._v(_vm._s((_vm$timer$trackable_t = _vm.timer.trackable_type) === null || _vm$timer$trackable_t === void 0 ? void 0 : _vm$timer$trackable_t.replace('_', ' ')))]), _vm._v(" "), _vm.timer.trackable && _vm.timer.trackable_type !== 'firm' ? _c('span', [_vm._v(" - "), _c('nuxt-link', {
    attrs: {
      "to": _vm.trackableLink
    }
  }, [_vm._v(_vm._s((_vm$timer$trackable = _vm.timer.trackable) === null || _vm$timer$trackable === void 0 ? void 0 : _vm$timer$trackable.name))])], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "flex flex-row items-center space-x-4"
  }, [_c('ui-text', {
    attrs: {
      "size": "x-small"
    }
  }, [_c('span', {
    staticClass: "text-p-gray-5"
  }, [_vm._v("From")]), _vm._v("\n              " + _vm._s(_vm.$dayjs(_vm.timer.start_date).format('hh:mm A')) + "\n            ")]), _vm._v(" "), _vm.timer.end_date ? _c('ui-text', {
    attrs: {
      "size": "x-small"
    }
  }, [_c('span', {
    staticClass: "text-p-gray-5"
  }, [_vm._v("Until")]), _vm._v("\n              " + _vm._s(_vm.$dayjs(_vm.timer.end_date).format('hh:mm A')) + "\n            ")]) : _c('ui-text', {
    staticClass: "text-p-green-dark",
    attrs: {
      "size": "x-small"
    }
  }, [_vm._v("\n              Still running\n            ")])], 1)])])], 1), _vm._v(" "), _c('div', {
    class: {
      'mx-auto': _vm.editable,
      'ml-auto': !_vm.editable
    }
  }, [_vm.timer.end_date !== null ? _c('ui-text', {
    staticClass: "text-p-gray-5",
    attrs: {
      "size": "small"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.secondsToHms(_vm.timer.time_elapsed_in_seconds)) + "\n      ")]) : _c('ui-text', {
    staticClass: "text-p-green-dark font-bold tracking-wider py-1 rounded-md border border-p-green-dark px-3",
    attrs: {
      "size": "small"
    }
  }, [_vm._v(_vm._s(_vm.currentTimer) + "\n      ")])], 1), _vm._v(" "), _vm.editable ? _c('div', [_c('file-actions', [_c('a', {
    staticClass: "cursor-pointer transform duration-300 ease-in-out hover:-translate-y-1",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.remove(_vm.timer);
      }
    }
  }, [_c('ui-icon-delete', {
    staticClass: "text-p-pink w-4 h-4"
  })], 1), _vm._v(" "), _c('a', {
    staticClass: "cursor-pointer transform duration-300 ease-in-out hover:-translate-y-1",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.edit(_vm.timer);
      }
    }
  }, [_c('ui-icon-edit', {
    staticClass: "text-p-gray-3 w-4 h-4"
  })], 1)])], 1) : _vm._e()])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }