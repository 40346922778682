var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-row items-center"
  }, [_c('div', {
    ref: "notification_bell",
    staticClass: "flex transition-all ease-in-out duration-300 cursor-pointer flex-row space-x-2 ",
    attrs: {
      "id": "notification-bell"
    },
    on: {
      "click": _vm.openNotificationPopup
    }
  }, [_c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_vm.active ? _c('div', {
    staticClass: "bg-p-pink text-white font-bold text-xxs rounded-full px-3 leading-6"
  }, [_vm._v(_vm._s(_vm.unread) + "\n      ")]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "relative hover:-translate-y-1 ease-in-out duration-300 text-p-gray-2 transform transition-all"
  }, [_c('ui-icon-notification', {
    staticClass: "w-6 h-6 transform transform-gpu duration-300 ease-in-out",
    class: {
      'scale-110 -rotate-6': _vm.active
    }
  }), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [!_vm.active ? _c('div', {
    staticClass: "bg-p-pink text-white font-bold text-xxs rounded-full w-2 h-2 absolute top-0 right-1"
  }) : _vm._e()])], 1)], 1), _vm._v(" "), !_vm.isClient ? _c('div', {
    staticClass: "flex ml-4"
  }, [_vm.user.current_account.current_time_entry ? _c('a', {
    staticClass: "text-p-green-dark cursor-pointer hover:-translate-y-1 ease-in-out duration-300 transform transition-all flex flex-row items-center",
    on: {
      "click": _vm.addTimeTracking
    }
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Timer running")]), _vm._v(" "), _c('ui-icon-clock', {
    staticClass: "animate-spin-slow h-6 w-6"
  })], 1) : _c('a', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Start timer',
      expression: "'Start timer'"
    }],
    staticClass: "cursor-pointer text-p-gray-2 hover:-translate-y-1 ease-in-out duration-300 transform transition-all",
    on: {
      "click": _vm.addTimeTracking
    }
  }, [_c('ui-icon-clock', {
    staticClass: "h-6 w-6"
  })], 1)]) : _vm._e(), _vm._v(" "), _c('modals-timer-control', {
    ref: "timerModal"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }