export default {
  namespaced: true,

  state: () => ({
    caseView: 'list',
    taskView: 'list',
    clientFilters: {},
    introducerFilters: {},
    employeeFilters: {},
    prospectFilters: {},
    taskFilters: {},
    planFilters: {},
  }),

  actions: {
    setCaseView({ commit }, view) {
      commit('SET_CASE_VIEW', view);
    },
    setTaskView({ commit }, view) {
      commit('SET_TASK_VIEW', view);
    },
    setClientFilters({ commit }, filters) {
      commit('SET_CLIENT_FILTERS', filters);
    },

    setEmployeeFilters({ commit }, filters) {
      commit('SET_EMPLOYEE_FILTERS', filters);
    },
    setIntroducerFilters({ commit }, filters) {
      commit('SET_INTRODUCER_FILTERS', filters);
    },

    setProspectFilters({ commit }, filters) {
      commit('SET_PROSPECT_FILTERS', filters);
    },
    setTaskFilters({ commit }, filters) {
      commit('SET_TASK_FILTERS', filters);
    },
    setPlanFilters({ commit }, filters) {
      commit('SET_PLAN_FILTERS', filters);
    },
  },

  mutations: {
    SET_CASE_VIEW(state, view) {
      state.caseView = view;
    },
    SET_TASK_VIEW(state, view) {
      state.taskView = view;
    },
    SET_CLIENT_FILTERS(state, filters) {
      state.clientFilters = filters;
    },

    SET_EMPLOYEE_FILTERS(state, filters) {
      state.employeeFilters = filters;
    },
    SET_INTRODUCER_FILTERS(state, filters) {
      state.introducerFilters = filters;
    },

    SET_PROSPECT_FILTERS(state, filters) {
      state.prospectFilters = filters;
    },
    SET_TASK_FILTERS(state, filters) {
      state.taskFilters = filters;
    },
    SET_PLAN_FILTERS(state, filters) {
      state.planFilters = filters;
    },
  },
}
