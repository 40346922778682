//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState, mapGetters} from 'vuex';
import BrandingColoursCSS from "../components/BrandingColoursCSS";
import hasTour from "@/mixins/hasTour";

export default {
  name: 'App',

  components: {BrandingColoursCSS},

  data() {
    return {
      menuOpen: false,
      cannyLoaded: false,
      showAccountBlock: false,
      firm: {
        logo: null,
        name: 'Plannr CRM'
      }
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapState(['appLoading']),
    ...mapGetters('system', ['isInProduction'])
  },

  mixins: [
    hasTour,
  ],

  mounted() {
    if (this.isInProduction === true) {
      this.initCanny();
    }
    this.getFirmDetails();
    this.$nuxt.$on('openNoteModal', this.openNote)
    this.$nuxt.$on('openCreateTaskFromAnywhereModal', this.openCreateTaskFromAnywhere)

  },

  beforeDestroy() {
    this.$nuxt.$off('openNoteModal')
    this.$nuxt.$off('openCreateTaskFromAnywhereModal')
  },

  methods: {

    openNote() {
      this.$refs.noteModal.open()
    },
    openCreateTaskFromAnywhere() {
      this.$refs.createTaskFromAnywhere.open()
    },

    getFirmDetails() {
      if (this.user.current_account.firm.logo) {
        this.firm.logo = this.user.current_account.firm.logo
        this.firm.name = this.user.current_account.firm.name
      }
    },

    toggleAccountBlock(e) {
      e.target.scrollIntoView(false);
      this.showAccountBlock = !this.showAccountBlock;
    },

    initCanny() {

      window.Canny('identify', {
        appID: '61d6f5909b18a30b5221a690',
        user: {
          // Replace these values with the current user's data
          email: this.user.email,
          name: this.user.name,
          id: this.user.uuid,
          // These fields are optional, but recommended:
          avatarURL: this.user.photo_url,
        },
      })

      window.Canny('initChangelog', {
        appID: '61d6f5909b18a30b5221a690',
        position: 'top',
        align: 'left',
      });


      this.cannyLoaded = true;
    },


    openMenu() {

      this.menuOpen = true

      let gsap = this.$gsap

      this.$nextTick(() => {

        let wrapper = this.$refs.mobileMenuWrapper
        let menu = this.$refs.mobileMenu

        this.tl = gsap.timeline({
          onComplete: () => {
          }
        });

        this.tl.set(menu, {x: -400})
          .to(wrapper, {opacity: 1, duration: 0.25})
          .to(menu, {x: 0, duration: 0.5})


      })

    },

    closeMenu() {

      let gsap = this.$gsap

      if (this.menuOpen) {

        let wrapper = this.$refs.mobileMenuWrapper
        let menu = this.$refs.mobileMenu

        this.tl = gsap.timeline({
          onComplete: () => {
            this.menuOpen = false
          }
        });

        this.tl.to(menu, {x: -400, duration: 0.5})
          .to(wrapper, {opacity: 0, duration: 0.25})

      }
    }

  },
  watch: {
    $route(to, from) {
      this.closeMenu()
    }
  },
}
