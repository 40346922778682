//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {Timer} from "easytimer.js";
import {mapState} from "vuex";
import timeEntryAPI from "@/api/timeEntries";

export default {
  name: "timer-modal",
  data() {
    return {
      timerHolder: null,
      currentTimer: '',
    }
  },
  computed: {
    ...mapState('user', ['user']),
  },
  mounted() {
    if (this.user.current_account.current_time_entry) {
      this.startLocalTimer(this.user.current_account.current_time_entry);
    }
    this.$nuxt.$on('timerStopped', this.stopTimer);
    this.$nuxt.$on('timerStarted', this.startLocalTimer);
  },

  beforeDestroy() {
    this.$nuxt.$off('timerStopped');
    this.$nuxt.$off('timerStarted');
  },

  methods: {
    openModal() {
      this.$refs.timetracking.open()
    },

    startLocalTimer(timer) {

      this.timerHolder = new Timer();
      this.timerHolder.start({
        precision: 'seconds',
        startValues: {
          seconds: this.$dayjs(new Date).diff(timer.start_date, 'seconds')
        }
      })
      this.timerHolder.addEventListener('secondsUpdated', (e) => {
        this.currentTimer = this.timerHolder.getTimeValues().toString()
      })

    },

    stopTimer() {
      this.timerHolder.stop();
      this.currentTimer = null;
      this.$nuxt.$authService.refreshState();
      this.timerHolder.removeEventListener('secondsUpdated');
    },

  }
}
