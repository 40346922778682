var render = function () {
  var _vm$notification$data, _vm$notification, _vm$notification$data2, _vm$notification$data3, _vm$notification2, _vm$notification2$tri;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ui-well', {
    key: _vm.notification.uuid,
    staticClass: "bg-p-gray-light mb-4 last:mb-0",
    attrs: {
      "padding": "4",
      "hoverable": true
    }
  }, [_c('div', {
    staticClass: "\n      notification-header\n      flex flex-row\n      justify-between\n      items-center\n      mb-2\n    "
  }, [_c('div', {
    staticClass: "notification-type flex flex-row items-center"
  }, [_c('div', {
    staticClass: "\n          rounded-sm\n          mr-2\n          text-white\n          flex flex-row\n          items-center\n          justify-center\n        "
  }, [_c('ui-icon-notification', {
    staticClass: "w-6 h-6 p-1 text-p-pink"
  })], 1), _vm._v(" "), _c('ui-title', {
    staticClass: "font-bold text-p-navy text-xs",
    attrs: {
      "size": "x-small"
    }
  }, [_vm._v(_vm._s(_vm.notification.data.title) + "\n      ")])], 1), _vm._v(" "), _c('ui-text', {
    staticClass: "text-p-gray-6 text-xs",
    attrs: {
      "size": "x-small"
    }
  }, [_vm._v(_vm._s(_vm.$dayjs(_vm.notification.created_at).fromNow()) + "\n    ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "notification-content flex flex-row justify-between"
  }, [_c('span', {
    staticClass: "rounded-full w-10 h-8 mr-2"
  }, [_c('avatar', {
    attrs: {
      "name": (_vm$notification$data = (_vm$notification = _vm.notification) === null || _vm$notification === void 0 ? void 0 : (_vm$notification$data2 = _vm$notification.data) === null || _vm$notification$data2 === void 0 ? void 0 : (_vm$notification$data3 = _vm$notification$data2.triggered_by) === null || _vm$notification$data3 === void 0 ? void 0 : _vm$notification$data3.name) !== null && _vm$notification$data !== void 0 ? _vm$notification$data : 'Plannr System',
      "size": 35,
      "rounded": ""
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "w-full"
  }, [_c('ui-title', {
    staticClass: "font-bold text-p-navy text-xs",
    attrs: {
      "size": "x-small"
    }
  }, [_vm._v("\n        " + _vm._s((_vm$notification2 = _vm.notification) === null || _vm$notification2 === void 0 ? void 0 : (_vm$notification2$tri = _vm$notification2.triggered_by) === null || _vm$notification2$tri === void 0 ? void 0 : _vm$notification2$tri.name) + "\n      ")]), _vm._v(" "), _c('ui-text', {
    staticClass: "text-p-gray-4 mb-2",
    attrs: {
      "size": "x-small"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.notification.data.description)
    }
  }, [_vm._v("\n        " + _vm._s(_vm.notification.data.description) + "\n      ")]), _vm._v(" "), _vm.notification.read_at === null ? _c('ui-button', {
    attrs: {
      "variant": "create",
      "size": "x-small"
    },
    on: {
      "click": _vm.redirectTo
    }
  }, [_vm._v("\n        View\n      ")]) : _vm._e(), _vm._v(" "), _vm.notification.read_at === null ? _c('ui-button', {
    attrs: {
      "variant": "action",
      "size": "x-small"
    },
    on: {
      "click": _vm.markAsRead
    }
  }, [_vm._v("\n        Dismiss\n      ")]) : _vm._e()], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }