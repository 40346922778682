//
//
//
//

export default {
    name: "SiteWideNotification",
    created(){
        this.$nuxt.$on('notification', this.openNotification);
    },
    data(){
        return {
            text: '',
            type: '',
            autoClose: false,
            opened: false,
        }
    },
    methods: {
        openNotification(text, type = 'alert', autoClose = true, timeout = 5000){
            this.text = text;
            this.type = type;
            this.opened = true;
            if( autoClose ){
                setTimeout(() => {
                    this.opened = false;
                }, timeout)
            }
        }
    }
}
