import clientAPI from '@/api/clients'
export default {
  namespaced: true,

  state: () => ({
    client: null
  }),

  getters: {},

  actions: {
    setActiveClient({commit}, client) {
      commit('SET_CLIENT', client);
    },

    async refreshClient({commit}, {client_uuid}){
      await clientAPI.getClient(client_uuid)
        .then(rsp => {
          commit('SET_CLIENT', rsp.data.data);
        })
    }
  },

  mutations: {
    SET_CLIENT(state, client) {
      state.client = client;
    },
  },
}
