//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import searchAPI from '../api/search';
import hasCurrentFirmUuid from "~/mixins/hasCurrentFirmUuid";
import navigatorAPI from "~/api/navigator";

export default {
  name: "SearchBlock",
  mixins: [
    hasCurrentFirmUuid
  ],
  data() {
    return {
      opened: false,
      form: {
        q: null,
      },
      results: [],
      activeFilters: [],
      filterOptions: [],
      orderedBy: ['client', 'note', 'message', 'case', 'conversation', 'file', 'address'],
    }
  },
  mounted() {
    this.$nuxt.$on('search', this.searchNow)
    this.$nuxt.$on('search_close', this.closePanel)
  },
  methods: {

    searchNow(query) {
      this.form.q = query;
      searchAPI.search({params: this.form})
        .then(rsp => {
          // reorder the results and return back to the page
          this.orderResults(rsp.data.data);
          this.opened = true;
          this.buildFilterGroups();
        })
    },

    orderResults(results){
      let holdingArray = [];
      let built = [];

      results.forEach((item) => {
        // is it something that should be ordered, or an unexpected item in the bagging area
        if(this.orderedBy.includes(item.model.type)) {
          if (holdingArray[item.model.type] === undefined) {
            holdingArray[item.model.type] = [item];
          } else {
            holdingArray[item.model.type].push(item);
          }
        } else {
          if (holdingArray['misc'] === undefined) {
            holdingArray['misc'] = [item];
          } else {
            holdingArray['misc'].push(item);
          }
        }
      })

      this.orderedBy.forEach(item => {
        if(holdingArray[item] !== undefined){
          built = [...built, ...holdingArray[item]];
        }
      })

      if( holdingArray['misc'] !== undefined ){
        built = [...built, ...holdingArray['misc']];
      }

      this.results = built;
    },


    closePanel() {
      this.$nuxt.$emit('searchClose');
      this.opened = false;
    },
    buildFilterGroups() {
      let options = [];
      this.results.forEach((item) => {
        if (!options.includes(item.model.type)) {
          options.push(item.model.type);
        }
      })
      this.filterOptions = options;
      this.activeFilters = options;
    },
  },
  computed: {
    filteredResultsList() {
      return this.results.filter(item => {
        return this.activeFilters.includes(item.model.type);
      })
    },

    url(){

    }
  }
}
