//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "customField",
  props: {
    value: {
      required: true,
      type: String | Number,
    },
    field: {
      type: Object,
      required: true,
    }
  },
  methods: {
    bubbleUp(data) {
      this.$emit('input', data);
    }
  }
}
