var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "divide-x divide-p-gray-6 flex-row overflow-hidden inline-flex rounded-lg w-auto border-p-gray-6 border transition-all ease-in-out duration-300"
  }, _vm._l(_vm.options, function (option, index) {
    return _c('a', {
      key: index,
      staticClass: "p-3 flex cursor-pointer hover:bg-p-green-pale hover:text-p-green-dark ",
      class: option.value === _vm.value ? 'text-white bg-p-green' : 'text-p-gray-2',
      on: {
        "click": function ($event) {
          return _vm.bubbleUp(option.value);
        }
      }
    }, [_vm._v("\n    " + _vm._s(option.label) + "\n  ")]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }