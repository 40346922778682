//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import notificationApi from '@/api/notifications'
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'notification-item-client',
  props: {
    notification: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters('user', ['isClient']),
  },
  methods: {
    redirectTo() {
      this.markAsRead(this.notification)

      this.$emit('clicked')

        if (this.notification.data.model_type === 'fact_find_request') {
          this.$router.push('/c/my-details/request/' + this.notification.data.model_uuid)
        }
        if (this.notification.data.model_type === 'document') {
          this.$router.push('/c/documents')
        }
        if (this.notification.data.model_type === 'file') {
          this.$router.push('/c/documents')
        }
        if (this.notification.data.model_type === 'conversation') {
          this.$router.push('/c/conversations/' + this.notification.data.model_uuid)
        }


    },

    markAsRead() {
      notificationApi.markAsRead(this.notification.uuid).then((rsp) => {
        this.$nuxt.$emit('NotificationRead', this.notification.uuid)
      })
      this.notification.read_at = new Date().toString()
    },
  },
}
