//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';
import notificationApi from '../api/notifications';

export default {
  name: "NotificationPopup",

  middleware: ['auth'],

  data() {
    return {
      notifications: [],
      popupVisible: false,
      popupEl: '',
      width: ''
    }
  },

  computed: {
    ...mapState('user', ['user']),
  },

  created() {
    this.$nuxt.$on('NewAppNotification', (e) => {
      this.grabNotifications();
    });

  },

  mounted() {
    this.popupEl = this.$refs.notification_popup;
    this.$gsap.set(this.popupEl, {y: -600})

    this.grabNotifications()

    let self = this;

    this.$nuxt.$on('OpenNotificationPopup', (e) => {
      self.showPopup();
    })

    this.$nuxt.$on('NotificationRead', (uuid) => {
      this.notifications = this.notifications.filter(e => e.uuid !== uuid);
    })

    this.$nuxt.$on('AllNotificationsRead', () => {
      this.notifications = []
    })

  },

  beforeDestroy() {
    this.$nuxt.$off('OpenNotificationPopup');
    this.$nuxt.$off('NewAppNotification');
    this.$nuxt.$off('NotificationRead');
    this.$nuxt.$off('AllNotificationsRead');
  },

  methods: {
    setPopup() {

      this.popupEl = this.$refs.notification_popup;

      this.checkWidth()

      window.addEventListener('resize', this.checkWidth);

      let amount = this.notifications.length * -300;

      if (amount > -600) {
        amount = -600;
      }

      this.$gsap.set(this.popupEl, {y: amount})

      this.tl = this.$gsap.timeline({paused: true})
        .to(this.popupEl, {y: 20, duration: 0.35, ease: "power2.inOut"})
        .to(this.popupEl, {y: 0, duration: 0.15, ease: "power2.inOut"})

    },

    showPopup() {
      if (this.popupVisible) {
        this.tl.reverse()
      } else {
        this.tl.play()
      }

      this.popupVisible = !this.popupVisible
    },

    checkWidth() {

      if (document.body && document.body.offsetWidth) {
        this.width = document.body.offsetWidth;
      }
      if (document.compatMode === 'CSS1Compat' && document.documentElement && document.documentElement.offsetWidth) {
        this.width = document.documentElement.offsetWidth;

      }
      if (window.innerWidth) {
        this.width = window.innerWidth;
      }

      if (this.width < 1024) {

        this.$gsap.set(this.popupEl, {right: 60})

      } else if (this.width < 1630) {

        this.$gsap.set(this.popupEl, {right: 110})

      } else {

        let offset = ((this.width - 1280 - 256) / 2) + 70
        this.$gsap.set(this.popupEl, {right: offset})

      }
    },

    markAllAsRead() {
      notificationApi.markAllAsRead().then(rsp => {
        this.$nuxt.$emit('AllNotificationsRead');
      });
      this.notifications.forEach(
        notification => {
          notification.read_at = new Date();
        }
      )
      this.showPopup()
    },

    markAsRead(notification) {
      notificationApi.markAsRead(notification.uuid).then(rsp => {
        this.$nuxt.$emit('NotificationRead', notification.uuid);
      });
      notification.read_at = new Date().toString();
    },

    grabNotifications() {
      notificationApi.getNotifications()
        .then(rsp => {
          this.notifications = rsp.data.data;
          this.setPopup()
        });
    }
  },

  watch: {
    notifications: {
      handler() {
      }
    }
  }
}
