//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ButtonChoice",
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String|Boolean,
      required: true,
    }
  },
  methods: {
    bubbleUp(v) {
      this.$emit('input', v)
    }
  }
}
