var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.known.includes(_vm.result.model.type) ? _c('div', [_vm.result.model.type === 'client' ? _c('div', {
    staticClass: "bg-p-aqua-pale rounded-full h-8 w-8 flex flex-row justify-around items-center text-p-aqua"
  }, [_c('ui-icon-person', {
    staticClass: "h-5 w-5",
    attrs: {
      "type": "outline"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.result.model.type === 'employee' ? _c('div', {
    staticClass: "bg-p-yellow-pale rounded-full h-8 w-8 flex flex-row justify-around items-center text-p-yellow-dark"
  }, [_c('ui-icon-person', {
    staticClass: "h-5 w-5",
    attrs: {
      "type": "outline"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.result.model.type === 'note' ? _c('div', {
    staticClass: "bg-p-yellow-pale rounded-full h-8 w-8 flex flex-row justify-around items-center text-p-yellow-dark"
  }, [_c('ui-icon-edit', {
    staticClass: "h-4 w-4",
    attrs: {
      "type": "outline"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.result.model.type === 'address' ? _c('div', {
    staticClass: "bg-p-gray-8 rounded-full h-8 w-8 flex flex-row justify-around items-center text-p-pink"
  }, [_c('ui-icon-point', {
    staticClass: "h-5 w-5",
    attrs: {
      "type": "outline"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.result.model.type === 'message' ? _c('div', {
    staticClass: "bg-p-gray-8 rounded-full h-8 w-8 flex flex-row justify-around items-center text-p-pink"
  }, [_c('ui-icon-chat', {
    staticClass: "h-5 w-5",
    attrs: {
      "type": "outline"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.result.model.type === 'conversation' ? _c('div', {
    staticClass: "bg-p-gray-8 rounded-full h-8 w-8 flex flex-row justify-around items-center text-p-pink"
  }, [_c('ui-icon-chat', {
    staticClass: "h-5 w-5",
    attrs: {
      "type": "outline"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.result.model.type === 'file' ? _c('div', {
    staticClass: "bg-p-aqua-pale rounded-full h-8 w-8 flex flex-row justify-around items-center text-p-aqua"
  }, [_c('ui-icon-file', {
    staticClass: "h-5 w-5",
    attrs: {
      "type": "outline"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.result.model.type === 'folder' ? _c('div', {
    staticClass: "bg-p-gray-8 rounded-full h-8 w-8 flex flex-row justify-around items-center text-p-pink"
  }, [_c('ui-icon-folder', {
    staticClass: "h-5 w-5",
    attrs: {
      "type": "outline"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.result.model.type === 'case' ? _c('div', {
    staticClass: "bg-p-green-pale rounded-full h-8 w-8 flex flex-row justify-around items-center text-p-green-dark"
  }, [_c('ui-icon-cases', {
    staticClass: "h-5 w-5",
    attrs: {
      "type": "outline"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.result.model.type === 'plan' ? _c('div', {
    staticClass: "bg-p-green-pale rounded-full h-8 w-8 flex flex-row justify-around items-center text-p-green-dark"
  }, [_c('ui-icon-cases', {
    staticClass: "h-5 w-5",
    attrs: {
      "type": "outline"
    }
  })], 1) : _vm._e()]) : _c('div', [_c('div', {
    staticClass: "bg-p-gray-8 rounded-full h-8 w-8 flex flex-row justify-around items-center text-p-gray-4"
  }, [_c('ui-icon-search', {
    staticClass: "h-5 w-5",
    attrs: {
      "type": "outline"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }