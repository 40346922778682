var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.opened ? _c('ui-info-bar', {
    staticClass: "fixed top-0 z-50",
    attrs: {
      "type": _vm.type
    }
  }, [_vm._v(_vm._s(_vm.text))]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }