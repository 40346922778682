var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-p-gray-3 p-3 flex flex-col md:flex-row searchResult"
  }, [_c('div', {
    staticClass: "flex flex-row space-x-4 md:w-1/6 items-center"
  }, [_c('search-result-icon', {
    attrs: {
      "result": _vm.result
    }
  }), _vm._v(" "), _c('ui-text', {
    staticClass: "w-1/5 capitalize",
    attrs: {
      "size": "small"
    }
  }, [_vm._v(_vm._s(_vm.result.model.type))])], 1), _vm._v(" "), _c('div', {
    staticClass: "md:w-3/5 py-3 md:p-0 md:ml-24 mr-auto space-y-2"
  }, [_c('nuxt-link', {
    attrs: {
      "to": _vm.url
    },
    on: {
      "click": _vm.bubbleClick
    }
  }, [_vm.result.model.type === 'client' ? _c('ui-title', {
    attrs: {
      "size": "x-small"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.result.model.name) + "\n      ")]) : _vm._e(), _vm._v(" "), _vm.result.model.type === 'employee' ? _c('ui-title', {
    attrs: {
      "size": "x-small"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.result.model.name) + "\n      ")]) : _vm._e(), _vm._v(" "), _vm.result.model.type === 'plan' ? _c('ui-title', {
    attrs: {
      "size": "x-small"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.result.model.client_name) + "\n      ")]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "items-start flex flex-col"
  }, [_c('ui-text', {
    attrs: {
      "size": "x-small"
    }
  }, [_c('span', {
    staticClass: "capitalize"
  }, [_vm._v(_vm._s(_vm.stripScores(_vm.result.highlights[0].field)))]), _vm._v(":\n        "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.result.highlights[0].snippet)
    }
  })]), _vm._v(" "), _vm.result.model.type === 'plan' ? _c('ui-text', {
    attrs: {
      "size": "x-small"
    }
  }, [_vm._v("\n        Name: " + _vm._s(_vm.result.model.name) + "\n      ")]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "items-center flex flex-row"
  }, [_c('ui-text', {
    attrs: {
      "size": "x-small"
    }
  }, [_vm.result.model.parent_type === 'message' ? _c('span', [_vm._v("Found in a conversation called " + _vm._s(_vm.pathname(_vm.result.model.path)))]) : _vm._e(), _vm._v(" "), _vm.result.model.parent_type === 'case' ? _c('span', [_vm._v("Found in a case called " + _vm._s(_vm.pathname(_vm.result.model.path)))]) : _vm._e(), _vm._v(" "), _vm.result.model.parent_type === 'client' && ['file', 'folder'].includes(_vm.result.model.type) ? _c('span', [_vm._v("Found in client called " + _vm._s(_vm.pathname(_vm.result.model.path)))]) : _vm._e()])], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "flex items-start md:items-center md:justify-center"
  }, [_c('nuxt-link', {
    attrs: {
      "to": _vm.url
    }
  }, [_c('ui-button', {
    attrs: {
      "size": "small",
      "type": "button",
      "variant": "outline"
    },
    on: {
      "click": _vm.bubbleClick
    }
  }, [_vm._v("View")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }