//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapState} from "vuex";
import notificationsApi from '../api/notifications';

export default {
  name: "NotificationBell",

  data() {
    return {
      active: true,
      unread: 0
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isClient']),
  },

  created() {
    this.$nuxt.$on('NewAppNotification', (e) => {
      this.addUnread();
    });

    this.$nuxt.$on('AllNotificationsRead', (e) => {
      this.setUnread(0)
    });

    this.$nuxt.$on('NotificationRead', (e) => {
      this.subUnread();
    });

    this.$nuxt.$on('startTimer', this.forceStartTimer);

    this.$nuxt.$on('openTimer', this.addTimeTracking);

    notificationsApi.getNotifications().then(
      rsp => {
        this.setUnread(rsp.data.unread);
      }
    )
  },

  beforeDestroy() {
    this.$nuxt.$off('NewAppNotification')
    this.$nuxt.$off('AllNotificationsRead')
    this.$nuxt.$off('NotificationRead')
    this.$nuxt.$off('startTimer')
    this.$nuxt.$off('openTimer')
  },

  methods: {
    navigateTo() {
      this.$router.push('/notifications')
    },
    openNotificationPopup() {
      // send event to notification popup in simplified
      this.$nuxt.$emit('OpenNotificationPopup')
    },
    addUnread() {
      this.unread++;
    },
    subUnread() {
      this.unread--;
    },
    setUnread(value) {
      this.unread = value
    },
    addTimeTracking() {
      this.$refs.timerModal.openModal();
    },
  },
}
