//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import clientAPI from '@/api/clients'
import casesAPI from '~/api/cases'
import boardsAPI from '~/api/boards.js'
import hasCustomFields from '@/mixins/hasCustomFields'
import employeeAPI from '@/api/employees'
import { mapGetters } from 'vuex'

export default {
  name: 'create-task-from-anywhere',
  mixins: [hasCustomFields],
  props: {
    preselect: {
      type: String,
      required: false,
    },
  },

  data: () => {
    return {
      modelType: 'task',
      employees: [],
      clientsLoading: true,
      casesLoading: true,
      taskListsLoading: true,
      clientList: [],
      taskLocation: [
        {
          name: 'Client',
          value: 'client',
        },
        {
          name: 'Case',
          value: 'case',
        },
      ],
      caseList: [],
      singleClient: '',
      selectedClient: '',
      selectedLocation: '',
      selectedCase: '',
      selectedList: '',
      singleCase: '',
      task_board_uuid: '',
      board: '',
      taskData: {
        name: '',
        description: '',
        due_at: null,
        participants: [],
      },
      errors: [],
    }
  },

  computed: {
    ...mapGetters('user', ['currentAccountUuid']),

    availableParticipants() {
      let employees = []
      employees = this.employees.map((item) => {
        return { name: item.name, uuid: item.uuid, type: 'employee' }
      })

      if (this.singleCase) {
        if (this.singleCase.participants.length > 0) {
          this.singleCase.participants.forEach((item) => {
            employees.push({ name: item.name, uuid: item.uuid, type: 'client' })
          })
        }
      }
      return employees
    },
  },

  mounted() {
    this.grabClients()
    this.taskData.participants = this.currentAccountUuid
  },

  methods: {
    grabClients() {
      clientAPI.getAllClients().then((rsp) => {
        this.clientList = rsp
        this.clientsLoading = false
      })
    },

    grabSingleClient() {
      clientAPI.getClient(this.selectedClient.uuid).then((rsp) => {
        this.singleClient = rsp.data.data
        this.task_board_uuid = this.singleClient.task_board_uuid
        this.grabBoard()
      })
    },

    grabCases() {
      let id = this.selectedClient.uuid
      if (id) {
        casesAPI
          .getClientCases(id, {
            params: {
              sort: 'name',
            },
          })
          .then((rsp) => {
            this.caseList = rsp.data.data
            this.casesLoading = false
          })
      }
    },

    grabSingleCase() {
      let id = this.selectedCase.uuid
      if (id) {
        casesAPI.getCase(id).then((rsp) => {
          this.singleCase = rsp.data.data
          this.task_board_uuid = this.singleCase.task_board_uuid
          this.grabBoard()
        })
      }
    },

    grabBoard() {
      boardsAPI
        .getBoard(this.task_board_uuid)
        .then((rsp) => {
          this.board = rsp.data.data
          this.taskListsLoading = false
          this.getEmployees()
        })
        .catch((rsp) => {
          this.$nuxt.$emit('notification', 'Something went wrong. Please try again', 'warning', true)
        })
    },

    getEmployees() {
      employeeAPI.getEmployees({ params: { per_page: 200 } }).then((rsp) => {
        this.employees = rsp.data.data
      })
    },

    open() {
      this.$refs.create.open()
    },

    close() {
      this.$refs.create.close()
    },

    createTask() {
      this.errors = []

      if (!this.taskData.name) {
        this.errors.push('Name required.')
      }
      if (!this.taskData.description) {
        this.errors.push('Description required.')
      }

      if (!this.errors.length > 0) {
        boardsAPI
          .addTask(this.task_board_uuid, this.selectedList.uuid, this.taskData)
          .then((rsp) => {
            this.$emit('finished');
            this.resetModal()
            this.close();
          })
          .catch((rsp) => {
            this.$nuxt.$emit(
              'notification',
              'Oops, something went wrong creating your task, please refresh and try again.',
              'alert_type',
              false
            )
          })
      }
    },

    resetModal() {
      this.clientsLoading = true
      this.casesLoading = true
      this.taskListsLoading = true
      this.employees = []
      this.clientList = []
      this.caseList = []
      this.selectedClient = ''
      this.singleClient = ''
      this.selectedLocation = ''
      this.selectedCase = ''
      this.selectedList = ''
      this.singleCase = ''
      this.board = ''
      this.taskData.name = ''
      this.taskData.description = ''
      this.taskData.due_at = ''
      this.taskData.participants = []
      this.custom_fields = {}
    },
  },

  watch: {
    selectedClient() {
      this.grabCases()
    },
    selectedCase() {
      this.grabSingleCase()
    },
    selectedLocation(newVal, oldVal) {
      if (newVal.value === 'client') {
        this.grabSingleClient()
      }
    },
  },
}
