var render = function () {
  var _vm$target$trackable_;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "rounded-md bg-p-navy-pale border border-p-navy-light p-4 mb-4"
  }, [_c('div', {
    staticClass: "flex flex-row items-center justify-between bg-white rounded-md mb-4"
  }, [_c('div', {
    staticClass: "w-full relative"
  }, [_c('ui-form-input', {
    staticClass: "w-full pr-20",
    attrs: {
      "variant": "naked",
      "placeholder": "What are you working on?",
      "autofocus": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _vm._v(" "), _vm.timerRunning ? _c('div', {
    staticClass: "absolute right-0 top-0"
  }, [_c('ui-text', {
    staticClass: "text-p-navy font-bold py-3 pr-4",
    attrs: {
      "size": ""
    }
  }, [_vm._v(_vm._s(_vm.timer))])], 1) : _vm._e()], 1)]), _vm._v(" "), _c('div', {
    staticClass: "flex flex-row items-center justify-between"
  }, [_c('div', {
    staticClass: "flex flex-row items-center"
  }, [!_vm.timerRunning ? _c('ui-button', {
    staticClass: "flex flex-row space-x-2 mr-4",
    attrs: {
      "variant": "blue_outline",
      "size": "small"
    },
    on: {
      "click": function ($event) {
        return _vm.link();
      }
    }
  }, [_c('svg', {
    staticClass: "h-5 w-5",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20",
      "fill": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z",
      "clip-rule": "evenodd"
    }
  })]), _vm._v(" "), _c('span', [_vm._v("Link to…")])]) : _vm._e(), _vm._v(" "), _c('div', [_c('ui-text', {
    staticClass: "leading-none uppercase tracking-wide opacity-50",
    attrs: {
      "size": "xx-small"
    }
  }, [_vm._v("Currently linked to\n          ")]), _vm._v(" "), _vm.target ? _c('ui-text', {
    staticClass: "leading-none capitalize",
    attrs: {
      "size": "small"
    }
  }, [_vm._v("\n            " + _vm._s((_vm$target$trackable_ = _vm.target.trackable_type) === null || _vm$target$trackable_ === void 0 ? void 0 : _vm$target$trackable_.replace('_', ' ')) + "\n          ")]) : _vm._e()], 1)], 1), _vm._v(" "), _vm.timerRunning ? _c('div', [_c('ui-button', {
    attrs: {
      "variant": "blue",
      "size": "small"
    },
    on: {
      "click": function ($event) {
        return _vm.stopTimer();
      }
    }
  }, [_c('span', [_vm._v("Stop Timer")])])], 1) : _c('ui-button', {
    staticClass: "flex flex-row space-x-3",
    attrs: {
      "variant": "blue",
      "size": "small"
    },
    on: {
      "click": function ($event) {
        return _vm.addTimerEntry();
      }
    }
  }, [_c('ui-icon-play', {
    staticClass: "w-5 h-5",
    attrs: {
      "type": "solid"
    }
  }), _vm._v(" "), _c('span', [_vm._v("Start Timer")])], 1)], 1), _vm._v(" "), _c('ErrorBlock', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasErrors,
      expression: "hasErrors"
    }],
    ref: "formErrorPanel",
    staticClass: "mt-4"
  })], 1), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.link_panel,
      expression: "link_panel"
    }],
    staticClass: "py-4 px-4 bg-white border border-p-gray-6 rounded-md mb-4"
  }, [_c('div', {
    staticClass: "flex flex-row items-center justify-between"
  }, [_c('ui-title', {
    staticClass: "text-p-navy font-bold",
    attrs: {
      "size": "small"
    }
  }, [_vm._v("Link Timer to…")]), _vm._v(" "), _c('ui-button', {
    attrs: {
      "variant": "secondary",
      "size": "small"
    },
    on: {
      "click": function ($event) {
        return _vm.link();
      }
    }
  }, [_vm._v("Cancel")])], 1), _vm._v(" "), _c('ui-text', {
    staticClass: "mb-4 -mx-4 px-4 border-b border-p-gray-6 pb-2 text-p-gray-4",
    attrs: {
      "size": "small"
    }
  }, [_vm._v("Link your timer to a\n        something in Plannr\n      ")]), _vm._v(" "), _c('form', {
    staticClass: "space-y-6",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('div', {
    staticClass: "flex flex-row items-center justify-between"
  }, [_c('ui-text', {
    staticClass: "text-p-gray-2",
    attrs: {
      "size": ""
    }
  }, [_vm._v("\n            Is this task entry related to a client?\n          ")]), _vm._v(" "), _c('div', [_c('boolean-button-choice', {
    model: {
      value: _vm.form.client_firm,
      callback: function ($$v) {
        _vm.$set(_vm.form, "client_firm", $$v);
      },
      expression: "form.client_firm"
    }
  })], 1)], 1), _vm._v(" "), _vm.form.client_firm ? _c('div', {
    staticClass: "space-y-6"
  }, [_c('div', [_c('client-selector', {
    model: {
      value: _vm.form.client_uuid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "client_uuid", $$v);
      },
      expression: "form.client_uuid"
    }
  })], 1), _vm._v(" "), _vm.form.client_uuid ? _c('div', {
    staticClass: "flex flex-row items-center justify-between"
  }, [_c('ui-text', {
    staticClass: "mr-2",
    attrs: {
      "size": ""
    }
  }, [_vm._v("\n              Would you like to link this to a case?\n            ")]), _vm._v(" "), _c('div', [_c('boolean-button-choice', {
    model: {
      value: _vm.form.for_case,
      callback: function ($$v) {
        _vm.$set(_vm.form, "for_case", $$v);
      },
      expression: "form.for_case"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.form.for_case ? _c('div', [_c('div', {
    staticClass: "mb-6"
  }, [_c('client-cases-multi-select', {
    attrs: {
      "client_uuid": _vm.form.client_uuid
    },
    model: {
      value: _vm.form.case_uuid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "case_uuid", $$v);
      },
      expression: "form.case_uuid"
    }
  })], 1), _vm._v(" "), _vm.form.case_uuid ? _c('div', {
    staticClass: "flex flex-row items-center justify-between"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_vm._v("\n                Would you like to link this to a task within this case?\n              ")]), _vm._v(" "), _c('div', [_c('boolean-button-choice', {
    model: {
      value: _vm.form.for_task,
      callback: function ($$v) {
        _vm.$set(_vm.form, "for_task", $$v);
      },
      expression: "form.for_task"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.form.for_task ? _c('div', [_c('div', {
    staticClass: "mb-6"
  }, [_c('case-task-select', {
    attrs: {
      "case_uuid": _vm.form.case_uuid
    },
    model: {
      value: _vm.form.task_uuid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "task_uuid", $$v);
      },
      expression: "form.task_uuid"
    }
  })], 1)]) : _vm._e()]) : _vm._e()]) : _vm._e()])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }