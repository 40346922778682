var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.clientList ? _c('ui-form-field', {
    staticClass: "w-full",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(_vm._s(_vm.label)), _vm.isRequired ? _c('span', {
          staticClass: "ml-1 text-p-pink"
        }, [_vm._v("*")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 1878010403)
  }, [_vm._v(" "), _c('multiselect', {
    attrs: {
      "options": _vm.clientList,
      "multiple": false,
      "loading": _vm.loading,
      "close-on-select": true,
      "clear-on-select": false,
      "preserve-search": true,
      "placeholder": "Select client",
      "label": "name",
      "track-by": "name"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('avatar', {
          staticClass: "option__image rounded-full overflow-hidden w-8 h-8",
          attrs: {
            "name": props.option.name,
            "size": 32
          }
        }), _vm._v(" "), _c('div', {
          staticClass: "option__desc ml-4 flex flex-col"
        }, [_c('span', {
          staticClass: "option__title -mb-4"
        }, [_vm._v(_vm._s(props.option.name))]), _vm._v(" "), _c('div', {
          staticClass: "flex flex-row"
        }, [props.option.email ? _c('span', {
          staticClass: "option__small text-p-gray-4 text-sm mr-4"
        }, [_vm._v("\n              " + _vm._s(props.option.email) + "\n            ")]) : _vm._e(), _vm._v(" "), props.option.date_of_birth ? _c('span', {
          staticClass: "option__small text-p-gray-4 text-sm"
        }, [_vm._v("\n              " + _vm._s(_vm.$helpers.basicDate(props.option.date_of_birth)) + "\n            ")]) : _vm._e()])])], 1)];
      }
    }], null, false, 3744846146),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }