import { render, staticRenderFns } from "./NewTimer.vue?vue&type=template&id=0a7ff626&"
import script from "./NewTimer.vue?vue&type=script&lang=js&"
export * from "./NewTimer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ErrorBlock: require('/vercel/path0/components/ErrorBlock.vue').default,BooleanButtonChoice: require('/vercel/path0/components/BooleanButtonChoice.vue').default,ClientSelector: require('/vercel/path0/components/ClientSelector.vue').default,ClientCasesMultiSelect: require('/vercel/path0/components/ClientCasesMultiSelect.vue').default,CaseTaskSelect: require('/vercel/path0/components/CaseTaskSelect.vue').default})
