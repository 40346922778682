export default {
  state: () => ({
    environment: null,
    firmDefaultBrandColours: null,
  }),

  getters: {
    isInProduction(state) {
      return state.environment === 'production';
    },
    isNotInProduction(state) {
      return state.environment !== 'production';
    }
  },

  actions: {
    setEnvironment({commit}, environment) {
      commit('SET_ENVIRONMENT', environment);
    },
    setFirmDefaultBrandColours({commit}, colours) {
      commit('SET_FIRM_DEFAULT_BRAND_COLOURS', colours);
    }
  },

  mutations: {
    SET_ENVIRONMENT(state, environment) {
      state.environment = environment;
    },
    SET_FIRM_DEFAULT_BRAND_COLOURS(state, colours) {
      state.firmDefaultBrandColours = colours;
    }
  },

  namespaced: true,
}
