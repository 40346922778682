//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ErrorBlock",
  props: {
    errors:{
      type: Array,
      required: true,
    }
  },

  data(){
    return {
    }
  },
  methods: {
  },
  computed: {
    hasErrors(){
      return Object.keys(this.errors).length > 0
    }
  }
}
