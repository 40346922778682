import introducerAPI from '@/api/introducers'

export default {
  namespaced: true,

  state: {
    introducer: {}
  },

  actions: {
    setIntroducer({commit}, data){
      commit('SET_INTRODUCER', data);
    },

    async refreshIntroducer({commit}, {uuid}){
      await introducerAPI.getIntroducer(uuid)
        .then(rsp => {
          commit('SET_INTRODUCER', rsp.data.data);
        })
    }
  },

  mutations: {
    SET_INTRODUCER(state, introducer) {
      state.introducer = introducer;
    }
  }

}
