//
//
//
//
//
//
//
//
//

export default {
  name: "Avatar",
  props: {
    avatar_url: {
      type: String|null,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: true,
      default: null
    },
    classes: {
      type: String,
      required: false,
    },
    size: {
      type: Number,
      required: false,
      default: 50
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
}
