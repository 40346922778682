var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ui-form-field', {
    attrs: {
      "classes": "text-p-navy capitalize"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.field.name.replaceAll('_', ' ')) + " "), _vm.field.is_required ? _c('span', {
          staticClass: "text-p-pink"
        }, [_vm._v("*")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c('div', [_vm.field.type === 'string' ? _c('div', [_c('ui-form-input', {
    staticClass: "w-full",
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": _vm.bubbleUp
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.field.type === 'number' ? _c('div', [_c('ui-form-input', {
    staticClass: "w-full",
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": _vm.bubbleUp
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.field.type === 'selection' ? _c('div', [_c('styled-select', {
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": _vm.bubbleUp
    }
  }, [_c('option', {
    domProps: {
      "value": null
    }
  }, [_vm._v("Not set")]), _vm._v(" "), _vm._l(_vm.field.selection_options, function (option) {
    return _c('option', {
      domProps: {
        "value": option
      }
    }, [_vm._v(_vm._s(option))]);
  })], 2)], 1) : _vm._e(), _vm._v(" "), _vm.field.type === 'boolean' ? _c('div', [_c('boolean-button-choice', {
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": _vm.bubbleUp
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.field.type === 'datetime' ? _c('div', [_c('ui-form-input', {
    staticClass: "w-full",
    attrs: {
      "type": "date",
      "value": _vm.$helpers.formatDate(_vm.value)
    },
    on: {
      "input": _vm.bubbleUp
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.field.help_text ? _c('ui-text', {
    staticClass: "text-p-gray-4 mt-2",
    attrs: {
      "size": "small"
    }
  }, [_vm._v(_vm._s(_vm.field.help_text))]) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }