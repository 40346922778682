//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ParticipantSelector',

  props: {
    value: {
      type: Array | String,
    },
    data: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: 'Select participants',
    },
    labelSize: {
      type: String,
      default: '',
    },
    selectLabel: {
      type: String,
      default: 'Select participants',
    },
    deselectLabel: {
      type: String,
      default: 'Press enter to remove',
    },
    multiple: {
      type: Boolean,
      default: true,
      required: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
      required: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      selectedList: [],
    }
  },

  mounted() {
    this.selectedList = this.data.filter((item) => {
      if (Array.isArray(this.value)) {
        return this.value.includes(item.uuid)
      } else {
        return this.value === item.uuid
      }
    })
  },

  watch: {
    data: {
      deep: true,
      handler(new_val) {
        this.selectedList = new_val.filter((item) => {
          if (Array.isArray(this.value)) {
            return this.value.includes(item.uuid)
          } else {
            return this.value === item.uuid
          }
        })
      },
    },

    selectedList(new_val) {
      if (this.multiple) {
        this.$emit(
          'input',
          new_val.map((item) => item.uuid)
        )
      } else {
        this.$emit('input', new_val)
      }
    },
  },
}
