//

import {mapState, mapGetters} from "vuex";

export default {
  name: "AppNotificationEventListener",

  created() {
    this.listenForNotifications(this.currentLoginUuid);
  },

  beforeDestroy() {
    this.$echo.leave('login.' + this.currentLoginUuid);
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['currentAccountUuid', 'currentLoginUuid'])
  },

  methods: {
    listenForNotifications(uuid) {
      this.$echo.private('login.' + uuid)
        .listen('AppNotification')
        .notification(notification => {
          this.$nuxt.$emit('NewAppNotification', notification);
        });
    },

  },

}
