var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.v,
      expression: "v"
    }],
    staticClass: "transition-all ease-in-out duration-200 text-p-gray-4 focus:outline-none focus:ring-1 focus:ring-p-pink",
    class: {
      'form-select': !_vm.small,
      'form-select-small': _vm.small,
      'opacity-50': _vm.disabled
    },
    attrs: {
      "disabled": _vm.disabled === true
    },
    on: {
      "input": _vm.emitMe,
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.v = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.emitMe]
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }