//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'NavSection',

  props: {
    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isExpanded: true,
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isMember']),

    classes() {
      let urls = []
      if (this.section.children !== undefined) {
        urls = this.section.children.map((item) => item.to)
      }
      urls.push(this.section.to)

      if (urls.includes(this.$route.path)) {
        return 'bg-p-navy-dark border-l-2 border-p-green text-branding-sidebar-active border-branding-sidebar-active bg-branding-sidebar-hover'
      } else {
        return 'text-white text-branding-sidebar-text'
      }
    },

    iconClasses() {
      let urls = []
      if (this.section.children !== undefined) {
        urls = this.section.children.map((item) => item.to)
      }
      urls.push(this.section.to)
      if (urls.includes(this.$route.path)) {
        return 'text-branding-sidebar-active'
      } else {
        return 'text-p-blue-light'
      }
    },
  },
}
