//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState, mapGetters} from 'vuex'

import vueFilePond from 'vue-filepond'
import "filepond/dist/filepond.min.css"
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FileTypes from '~/data/filetypes-en.csv'
import {processingStatuses} from '~/helpers/filepond'
import filesAPI from '../api/files'

import hasCurrentFirmUuid from "~/mixins/hasCurrentFirmUuid";

const FilePond = vueFilePond(FilePondPluginFileMetadata, FilePondPluginFileValidateSize, FilePondPluginFileValidateType)

export default {
    name: 'FileUploader',

    mixins: [hasCurrentFirmUuid],

    components: {
        FilePond,
        FileTypes,
    },

    mounted() {
        // Alert the user they still have files uploading if they try to quit the page.

        window.onbeforeunload = () => {
            let files = this.$refs.filePond.getFiles().filter(file => {
            return processingStatuses.includes(file.status)
            })

            return files.length > 0 ? true : null
        }

        this.$nuxt.$on('fileUploader', this.uploadFiles)
    },

  data() {
      return {
          queue: [],
      };
  },

  computed: {
    ...mapState('session', ['csrfToken']),
    ...mapGetters('user', ['currentAccountUuid', 'currentFirmUuid']),

    validFileTypes() {
      return FileTypes.map(fileType => fileType['type'])
    },

    serverConfiguration() {
      let token = this.$auth.strategy.token.get();
      let xsrfToken = this.$auth.$storage.getCookies()['XSRF-TOKEN'];

      return {
        url: filesAPI.getUploadUrl(),
        process: {
          withCredentials: true,
          onload: (response) => {
            // This is the unique serverID needed for Filepond for patch requests
            const json = JSON.parse(response.response)
            return json.data.upload_id
          },
          onerror: (response) => response.data,
          headers: (file, metadata) => {
            return {
              'X-Requested-With': 'XMLHttpRequest',
              'Authorization': token,
              'FP-Filename': file.name,
              'FP-Type': file.type,
              'FP-Size': file.size,
              'FP-Last-Modified': file.lastModified,
              'X-XSRF-TOKEN': xsrfToken,
              'X-PLANNR-ACCOUNT-UUID': this.currentAccountUuid
            }
          }
        },
        patch: {
          withCredentials: true,
          onerror: (error) => console.error(error),
          headers: (file, metadata) => {
            return {
              'X-Requested-With': 'XMLHttpRequest',
              'Authorization': token,
              'FP-Filename': file.name ? file.name : file.file.name,
              'FP-Type': file.type,
              'FP-Size': file.file ? file.file.size : file.size,
              'FP-Last-Modified': file.lastModified,
              'Upload-Length': file.file ? file.file.size : file.size,
              'Upload-Name': file.name ? file.name : file.file.name,
              'Upload-Offset': file.file ? file.offset : undefined,
              'X-XSRF-TOKEN': xsrfToken,
              'X-PLANNR-ACCOUNT-UUID': this.currentAccountUuid
            }
          }
        },
        revert: null,
        restore: null,
        load: null,
        fetch: null,
      }
    },
  },

  methods: {
    uploadFiles(files, folderUuid) {
      return new Promise(async (resolve, reject) => {
        files = Array.from(files)

        if (files.length === 0) {
            resolve(null)
            return
        }

        try {
          let filesToUpload = await this.$refs.filePond.addFiles(files)
          let data = filesToUpload.map(file => this.mapFile(file))
          let response = await filesAPI.uploadFiles({files: data})
          this.$refs.filePond.processFiles().catch(() => {})
          this.$nuxt.$emit('handleFiles', response.data.data)
          this.queue = this.queue.concat(response.data.data)
          resolve(response.data.data)

        } catch (error) {
          reject(error)
        }
      })
    },

    addFile(error, file) {
      file.setMetadata('queue_id', file.id)
    },

    processFile(error, file) {

      let index = this.queue.findIndex(queuedFile => queuedFile.queue_id === file.id)

      if (index !== -1) {
          this.queue[index].status = error ? 'failed' : 'uploaded'
      }

      let timeout = error ? 30000 : 5000

      setTimeout(() => {
        this.queue = this.queue.filter(queuedFile => queuedFile.queue_id !== file.id)
        this.$refs.filePond.removeFile(file.id)
      }, timeout);
    },

    processFileProgress(file, progress) {
      let index = this.queue.findIndex(queuedFile => queuedFile.queue_id === file.id)

      if (index === -1) {
        return
      }

      this.queue[index].status = 'uploading'
      this.queue[index].progress = progress
    },

    updateFile(file) {
        let index = this.queue.findIndex(queuedFile => queuedFile.id === file.queue_id)

        if (index === -1) {
            return
        }

        this.queue.splice(index, 1, file)
    },

    mapFile(file) {
        return {
            queue_id: file.id,
            filename: file.filename,
            name: file.filenameWithoutExtension,
            extension: file.fileExtension,
            type: file.fileType,
            size: file.fileSize,
            last_modified: file.file.lastModified,
        }
    },
  },
}
