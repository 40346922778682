//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import navigatorAPI from "@/api/navigator";

export default {
  name: "ResultItem",
  props: {
    result: {
      type: Object,
      required: true,
    }
  },
  computed: {
    url() {

      if (this.result.model.type === 'personal_detail') {
        return '/clients/' + this.result.model.parent_uuid
      }

      if (this.result.model.type === 'client') {
        return '/clients/' + this.result.model.uuid
      }

      if (this.result.model.type === 'address') {
        return '/clients/' + this.result.model.parent_uuid
      }

      if (this.result.model.type === 'note') {
        if (this.result.model.parent_type === 'client') {
          return '/clients/' + this.result.model.parent_uuid + '/notes'
        }
        if (this.result.model.parent_type === 'case') {
          return '/case/' + this.result.model.parent_uuid + '/notes'
        }
        if (this.result.model.parent_type === 'plan') {
          return '/plans/' + this.result.model.parent_uuid + '/notes'
        }
        if (this.result.model.parent_type === 'task') {
          return '/task/' + this.result.model.parent_uuid + '/notes'
        }
      }

      if (this.result.model.type === 'case') {
        return '/case/' + this.result.model.uuid
      }

      if (this.result.model.type === 'message') {
        return '/conversations/' + this.result.model.parent_uuid
      }

      if (this.result.model.type === 'conversations') {
        return '/conversations/' + this.result.model.uuid
      }

      if (this.result.model.type === 'employee') {
        return '/settings/employees/' + this.result.model.uuid
      }

      if (this.result.model.type === 'file') {
        if (this.result.model.parent_type === 'client') {
          return '/clients/' + this.result.model.parent_uuid + '/documents'
        }
        if (this.result.model.parent_type === 'case') {
          return '/case/' + this.result.model.parent_uuid + '/documents'
        }
        if (this.result.model.parent_type === 'message') {
          // get the message, then grab the conversation from that
          navigatorAPI.getNavigator('message', this.result.model.parent_uuid)
            .then(rsp => {
              return '/conversations/' + rsp.data.data.conversation_uuid
            })
        }
      }
      if (this.result.model.type === 'plan') {
        console.log(this.result)
        return '/plans/' + this.result.model.uuid
      }

      if (this.result.model.type === 'folder') {
        if (this.result.model.parent_type === 'client') {
          return '/clients/' + this.result.model.parent_uuid + '/documents/'
        }
        if (this.result.model.parent_type === 'prospect') {
          return '/prospects/' + this.result.model.parent_uuid + '/documents/'
        }
        if (this.result.model.parent_type === 'case') {
          return '/case/' + this.result.model.parent_uuid + '/documents/'
        }
      }

      return '/dashboard/';

    }
  },

  methods: {
    stripScores(key) {
      return key.replaceAll('_', ' ');
    },
    pathname(path) {
      return path ? path.split('/')[0] : null;
    },
    bubbleClick() {
      this.$emit('closePanel');
    }
  }
}
