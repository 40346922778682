export default {
    data(){
        return {
            tour: null,
        }
    },

    created(){
        this.defineTour();

        this.$nuxt.$on('triggerTour', this.openTour);

    },

    methods: {

        defineTour(){

        },

        openTour(){
            if( this.tour === null || this.tour === undefined){
              this.$nuxt.$emit('notification', 'Sadly, there\'s no tour for this page. Why not request one one our roadmap?', 'alert', true);
            } else {
                this.tour.start();
            }
        }

    },

    beforeDestroy() {
        this.$nuxt.$off('triggerTour', this.openTour);
    }
}
