//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import caseAPI from '@/api/cases';
import boardAPI from '@/api/boards';

export default {
  name: "CaseTaskSelect",

  props: {
    value: {
      type: String|null,
      required: true,
    },
    case_uuid: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      selectedList: [],
      tasks: [],
      loading: true,
      case: {},
      board: {
        lists: []
      },
    }
  },

  created() {
    this.getCase(this.case_uuid);
  },

  methods: {

    grabTasks() {
      this.loading = true;

    },

    getCase(case_uuid){
      caseAPI.getCase(case_uuid)
      .then(rsp => {
        this.case = rsp.data.data;
        this.getBoard();
      })
    },

    getBoard(){
      boardAPI.getBoard(this.case.task_board_uuid)
      .then(rsp => {
        this.board = rsp.data.data;
        this.getTasks();
      })
    },

    getTasks(){
      this.board.lists.forEach(list => {
        if( list.tasks_count > 0 ){
          boardAPI.getTasks(this.board.uuid, list.uuid)
          .then(rsp => {
            rsp.data.data.forEach(task => {
              this.tasks.push(task);
            })
          })
        }
      })
      this.loading = false;

    }

  },

  watch: {
    case_uuid(new_val) {
      this.getCase(new_val);
    },

    selectedList(new_val) {
      if (!Array.isArray(new_val)) {
        this.$emit('input', new_val.uuid);
        return;
      }

      let reduced = new_val.map(item => item.uuid);

      this.$emit('input', reduced);
    }
  }
}
