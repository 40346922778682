var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ui-sidebar-modal', {
    ref: "create",
    attrs: {
      "content_width": "max-w-4xl",
      "has_header": true,
      "has_icon": ""
    },
    scopedSlots: _vm._u([{
      key: "header_title",
      fn: function () {
        return [_vm._v(" Create a new task… ")];
      },
      proxy: true
    }, {
      key: "header_icon",
      fn: function () {
        return [_c('ui-icon-square-tick', {
          staticClass: "w-12 h-12 text-p-gray-7",
          attrs: {
            "type": "outline"
          }
        })];
      },
      proxy: true
    }, {
      key: "primary_button",
      fn: function () {
        return [_c('ui-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.createTask
          }
        }, [_vm._v("Save")])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _vm._v(" "), _c('form', {
    staticClass: "space-y-6 pb-12",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createTask.apply(null, arguments);
      }
    }
  }, [_vm.clientList ? _c('ui-form-field', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("Client List")];
      },
      proxy: true
    }], null, false, 664249568)
  }, [_vm._v(" "), _c('multiselect', {
    attrs: {
      "options": _vm.clientList,
      "multiple": false,
      "loading": _vm.clientsLoading,
      "close-on-select": true,
      "clear-on-select": false,
      "preserve-search": true,
      "placeholder": "Select client",
      "label": "name",
      "track-by": "name"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('avatar', {
          staticClass: "option__image rounded-full overflow-hidden w-8 h-8",
          attrs: {
            "name": props.option.name,
            "size": 32
          }
        }), _vm._v(" "), _c('div', {
          staticClass: "option__desc ml-4"
        }, [_c('span', {
          staticClass: "option__title"
        }, [_vm._v(_vm._s(props.option.name))])])], 1)];
      }
    }], null, false, 2644844527),
    model: {
      value: _vm.selectedClient,
      callback: function ($$v) {
        _vm.selectedClient = $$v;
      },
      expression: "selectedClient"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.selectedClient ? _c('ui-form-field', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("Task Location")];
      },
      proxy: true
    }], null, false, 654568459)
  }, [_vm._v(" "), _c('multiselect', {
    attrs: {
      "options": _vm.taskLocation,
      "multiple": false,
      "close-on-select": true,
      "clear-on-select": false,
      "preserve-search": true,
      "placeholder": "Select client",
      "label": "name",
      "track-by": "name"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('div', {
          staticClass: "option__desc ml-4"
        }, [_c('span', {
          staticClass: "option__title"
        }, [_vm._v(_vm._s(props.option.name))])])])];
      }
    }], null, false, 2448576664),
    model: {
      value: _vm.selectedLocation,
      callback: function ($$v) {
        _vm.selectedLocation = $$v;
      },
      expression: "selectedLocation"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.selectedLocation.value == 'case' ? _c('ui-form-field', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("Case List")];
      },
      proxy: true
    }], null, false, 680204909)
  }, [_vm._v(" "), _c('multiselect', {
    attrs: {
      "options": _vm.caseList,
      "multiple": false,
      "loading": _vm.casesLoading,
      "close-on-select": true,
      "clear-on-select": false,
      "preserve-search": true,
      "placeholder": "Select case",
      "label": "name",
      "track-by": "name"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('div', {
          staticClass: "option__desc ml-4"
        }, [_c('span', {
          staticClass: "option__title"
        }, [_vm._v(_vm._s(props.option.name))])])])];
      }
    }], null, false, 2448576664),
    model: {
      value: _vm.selectedCase,
      callback: function ($$v) {
        _vm.selectedCase = $$v;
      },
      expression: "selectedCase"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.board ? _c('ui-form-field', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("Select List")];
      },
      proxy: true
    }], null, false, 566180657)
  }, [_vm._v(" "), _c('multiselect', {
    attrs: {
      "options": _vm.board.lists,
      "multiple": false,
      "loading": _vm.taskListsLoading,
      "close-on-select": true,
      "clear-on-select": false,
      "preserve-search": true,
      "placeholder": "Select list",
      "label": "name",
      "track-by": "name"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('avatar', {
          staticClass: "option__image rounded-full overflow-hidden w-8 h-8",
          attrs: {
            "name": props.option.name,
            "size": 32
          }
        }), _vm._v(" "), _c('div', {
          staticClass: "option__desc ml-4"
        }, [_c('span', {
          staticClass: "option__title"
        }, [_vm._v(_vm._s(props.option.name))])])], 1)];
      }
    }], null, false, 2644844527),
    model: {
      value: _vm.selectedList,
      callback: function ($$v) {
        _vm.selectedList = $$v;
      },
      expression: "selectedList"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.selectedList ? _c('div', [_c('ui-form-field', {
    staticClass: "mb-8",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("Task Name")];
      },
      proxy: true
    }], null, false, 1446956209)
  }, [_vm._v(" "), _c('ui-form-input', {
    staticClass: "w-full",
    attrs: {
      "variant": "light",
      "placeholder": "Task Name"
    },
    model: {
      value: _vm.taskData.name,
      callback: function ($$v) {
        _vm.$set(_vm.taskData, "name", $$v);
      },
      expression: "taskData.name"
    }
  })], 1), _vm._v(" "), _c('ui-form-field', {
    staticClass: "mb-8",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("Description")];
      },
      proxy: true
    }], null, false, 1435420477)
  }, [_vm._v(" "), _c('ui-expanding-textarea', {
    staticClass: "transition-all ease-in-out duration-200 w-full bg-p-gray-light text-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-p-pink focus:bg-gray-50 px-4 py-3",
    attrs: {
      "placeholder": "Task Description"
    },
    model: {
      value: _vm.taskData.description,
      callback: function ($$v) {
        _vm.$set(_vm.taskData, "description", $$v);
      },
      expression: "taskData.description"
    }
  })], 1), _vm._v(" "), _c('ui-form-field', {
    staticClass: "mb-8",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("Due Date")];
      },
      proxy: true
    }], null, false, 3906584635)
  }, [_vm._v(" "), _c('div', {
    staticClass: "mb-8 transition-all ease-in-out duration-200 w-full bg-p-gray-light text-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-p-pink focus:bg-gray-50 px-4 py-2"
  }, [_c('VueDatePicker', {
    attrs: {
      "contentClass": "task-date-picker",
      "no-header": "",
      "color": '#53DBB8'
    },
    model: {
      value: _vm.taskData.due_at,
      callback: function ($$v) {
        _vm.$set(_vm.taskData, "due_at", $$v);
      },
      expression: "taskData.due_at"
    }
  })], 1)]), _vm._v(" "), _vm.employees ? _c('ui-form-field', {
    staticClass: "mb-8"
  }, [_c('participant-selector', {
    attrs: {
      "label": "Select who should be assigned to this task",
      "data": _vm.availableParticipants
    },
    model: {
      value: _vm.taskData.participants,
      callback: function ($$v) {
        _vm.$set(_vm.taskData, "participants", $$v);
      },
      expression: "taskData.participants"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.customFields.length ? _c('hr') : _vm._e(), _vm._v(" "), _vm.customFields.length ? _c('div', {
    staticClass: "gap-6 mt-6"
  }, _vm._l(_vm.customFields, function (field) {
    return _c('custom-field', {
      key: field.uuid,
      attrs: {
        "field": field
      },
      model: {
        value: _vm.modalData.item.custom_fields[field.reference],
        callback: function ($$v) {
          _vm.$set(_vm.modalData.item.custom_fields, field.reference, $$v);
        },
        expression: "modalData.item.custom_fields[field.reference]"
      }
    });
  }), 1) : _vm._e(), _vm._v(" "), _c('ErrorBlockFrontEnd', {
    attrs: {
      "errors": _vm.errors
    }
  }), _vm._v(" "), _c('ErrorBlock', {
    ref: "formErrorPanel"
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }