//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "HeaderSearchPanel",
  props: {
    naked: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      search: '',
    }
  },
  created() {
    this.$nuxt.$on('searchClose', this.clear);
  },
  methods: {
    triggerEmit() {
      if (this.search.length > 0) {
        this.$nuxt.$emit('search', this.search)
      } else {
        this.$nuxt.$emit('search_close');
      }
    },
    clear() {
      this.search = '';
    },
    openRocketBar() {
      this.$nuxt.$emit('openRocketBar');
    }
  }
}
