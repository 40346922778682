//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import timeEntryAPI from '@/api/timeEntries'
import {mapState} from "vuex";
import checkForErrors from "@/helpers/checkForErrors";

export default {
  name: "NewTimer",
  props: {
    timer: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      link_panel: false,
      extTimer: null,
      timerExists: false,
      elapsed: null,
      timerEntries: [],
      form: {
        name: null,
        for_case: null,
        for_task: null,
        client_firm: null,
        client_uuid: null,
        case_uuid: null,
        task_uuid: null,
      },
      currentTimer: {
        uuid: null,
      },
      hasErrors: false
    }
  },
  computed: {
    ...mapState('user', ['user']),
    timerRunning() {
      return this.user.current_account.current_time_entry;
    },
    target() {
      if (this.form.client_firm !== true) {
        return {
          trackable_type: 'firm',
          trackable_uuid: this.user.current_account.firm.uuid
        }
      }

      if (this.form.client_uuid !== null && this.form.for_case !== true) {
        return {
          trackable_type: 'client',
          trackable_uuid: this.form.client_uuid
        }
      }

      if (this.form.for_task !== true && this.form.for_case === true) {
        return {
          trackable_type: 'case',
          trackable_uuid: this.form.case_uuid
        }
      }

      if (this.form.for_task === true && this.form.task_uuid !== null) {
        return {
          trackable_type: 'board_task',
          trackable_uuid: this.form.task_uuid
        }
      }
    }
  },
  mounted() {
    this.checkForRunningTimer();
  },

  methods: {
    link() {
      this.link_panel = !this.link_panel;
    },

    checkForRunningTimer() {
      if (this.user.current_account.current_time_entry) {
        this.currentTimer = this.user.current_account.current_time_entry;
        this.form.name = this.currentTimer.name;
      }
    },

    stopTimer() {
      timeEntryAPI.stopTimer(this.user.current_account.current_time_entry.uuid)
        .then(rsp => {
          this.$nuxt.$emit('timerStopped');
          this.form = {
            name: null,
            for_case: null,
            for_task: null,
            client_firm: null,
            client_uuid: null,
            case_uuid: null,
            task_uuid: null,
          }
          this.$nuxt.$emit('notification', 'Timer stopped');
      }).catch(err => {
          checkForErrors.process(err, this.$refs.formErrorPanel);
      })
    },

    addTimerEntry() {
      this.hasErrors = false;
      let data = {
        ...this.target,
        name: this.form.name,
        start_date: this.$dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      };
      timeEntryAPI.create(data)
        .then(rsp => {
          this.$nuxt.$authService.refreshState();
          this.link();
          this.$nuxt.$emit('timerStarted', rsp.data.data);
          this.$nuxt.$emit('notification', 'New timer started');
        }).catch(err => {
        this.hasErrors = true;
        checkForErrors.process(err, this.$refs.formErrorPanel);
      })
    },
  }
}
