export default {
    namespaced: true,

    state: () => ({
        firm: null
    }),

    getters: {},

    actions: {

    },

    mutations: {

    },
}