import plansAPI from '@/api/plans'

export default {
  namespaced: true,

  state: () => ({
    plan: {
      name: null,
      sub_accounts: [],
      type: '',
      plan_type: '',
      main_account: {
        policy_number: null,
      },
      tags: []
    },
    loaded: false,
    contributions: [],
    withdrawals: [],
  }),

  getters: {},

  actions: {
    setPlan({commit}, plan) {
      commit('SET_PLAN', plan);
    },

    setLoaded({commit}, value) {
      commit('SET_LOADED', value);
    },

    setContributions({commit}, value) {
      commit('SET_CONTRIBUTIONS', value);
    },

    setWithdrawals({commit}, value) {
      commit('SET_WITHDRAWALS', value);
    },

    refreshPlan({dispatch, commit}, {plan_uuid}) {
      plansAPI.get(plan_uuid, {params: {include: 'fields,clients,sub_accounts,latest_valuations,account,tags'}})
        .then(rsp => {
          commit('SET_PLAN', rsp.data.data);
          commit('SET_LOADED', true);
          return rsp.data
        })
    },
  },

  mutations: {
    SET_PLAN(state, plan) {
      state.plan = plan;
    },
    SET_LOADED(state, value) {
      state.loaded = value;
    },
    SET_CONTRIBUTIONS(state, value) {
      state.contributions = value;
    },
    SET_WITHDRAWALS(state, value) {
      state.withdrawals = value;
    },
  },
}
