var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasErrors,
      expression: "hasErrors"
    }],
    staticClass: "rounded-md bg-red-50 p-4"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "flex-shrink-0"
  }, [_c('svg', {
    staticClass: "h-5 w-5 text-red-400",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20",
      "fill": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z",
      "clip-rule": "evenodd"
    }
  })])]), _vm._v(" "), _c('div', {
    staticClass: "ml-3"
  }, [_c('h3', {
    staticClass: "text-sm font-medium text-red-800"
  }, [_vm._v("\n        There were " + _vm._s(_vm.errors.length) + " errors with your submission\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "mt-2 text-sm text-red-700"
  }, _vm._l(_vm.errors, function (fields) {
    return _c('ul', {
      staticClass: "list-disc pl-5 space-y-1",
      attrs: {
        "role": "list"
      }
    }, _vm._l(fields, function (field) {
      return _c('li', [_vm._v("\n            " + _vm._s(field) + "\n          ")]);
    }), 0);
  }), 0)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }