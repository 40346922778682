//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import modalManager from "@/helpers/modalManager";
import timeEntryAPI from "@/api/timeEntries";
import {mapState} from "vuex";

export default {
  name: "SlashSearch",
  data() {
    return {
      opened: false,
      command: '',
      suggestions: [
        {
          command: 'tour',
          description: 'Start a tour explaining the current page'
        },
        {
          command: 'search {term}',
          description: 'Search for a specific term'
        },
        {
          command: 'create client',
          description: 'Create a new client in Plannr'
        },
        {
          command: 'create prospect',
          description: 'Create a new prospect in Plannr'
        },
        {
          command: 'create timer timer_task',
          description: 'Start a timer running and pre-fill the timer description with the entered text'
        },
        {
          command: 'create task',
          description: 'Create a new task in Plannr'
        },
        {
          command: 'create case',
          description: 'Create a new case in Plannr'
        },
        {
          command: 'create employee',
          description: 'Create a new employee in Plannr'
        },
        {
          command: 'clients',
          description: 'Navigate to the clients screen'
        },
        {
          command: 'cases',
          description: 'Navigate to the cases screen'
        },
        {
          command: 'prospects',
          description: 'Navigate to the prospects screen'
        },
        {
          command: 'create note',
          description: 'Add a quick note to anything'
        },
      ],
      cycling: '',
      cycleIndex: 0,
    }
  },

  computed: {
    ...mapState('user', ['user']),
    activeSuggestions() {

      if (this.command === 'help') {
        return this.suggestions;
      }

      if (this.command !== '') {
        return this.suggestions.filter((item) => {
          return item.command.includes(this.command.toLowerCase()) || item.description.toLowerCase().includes(this.command.toLowerCase());
        })
      }
      return this.suggestions;
    }
  },

  mounted(){
    this.$nuxt.$on('openRocketBar', this.open);
  },

  beforeDestroy() {
    this.$nuxt.$off('openRocketBar');
  },

  methods: {
    open() {
      this.opened = !this.opened;
      this.$nextTick(() => this.$refs.commandSearch.focus())
    },
    go() {
      this.helping = false;
      let comm = this.command.toLowerCase();

      if (comm === 'help') {
        this.helping = true;
      }else if (comm === 'create client') {
        modalManager.openModal('createClient', this.$router, '/clients');
        this.reset();
      }else if (comm === 'create prospect') {
        modalManager.openModal('createClient', this.$router, '/prospects');
        this.reset();
      }else if (comm === 'create case') {
        modalManager.openModal('createCase', this.$router, '/cases');
        this.reset();
      }else if (comm === 'create employee') {
        modalManager.openModal('createEmployee', this.$router, '/settings/employees');
        this.reset();
      }else if (comm.startsWith('create timer')) {
        let term = comm.replace('create timer ', '');
        this.forceStartTimer(term);
        this.reset();
      }else if (comm === 'cases') {
        this.$router.push({name: 'cases'});
        this.reset();
      }else if (comm === 'create note') {
        this.$nuxt.$emit('openNoteModal')
        this.reset();
      }else if (comm === 'create task') {
        this.$nuxt.$emit('openCreateTaskFromAnywhereModal')
        this.reset();
      }else if (comm.startsWith('search ')) {
        let term = comm.replace('search ', '');
        this.$nuxt.$emit('search', term);
        this.reset();
      }else if (comm === 'clients') {
        this.$router.push('/clients');
        this.reset();
      }else if (comm === 'prospects') {
        this.$router.push('/prospects');
        this.reset();
      } else if (comm === 'tour') {
        this.reset();
        this.$nuxt.$emit('triggerTour');
      } else {
        let term = comm.replace('search ', '');
        this.$nuxt.$emit('search', term);
        this.reset();
      }
    },
    reset() {
      this.opened = false;
      this.command = '';
    },
    cycleOptions(event) {

      switch (event.srcKey) {
        case 'up':
          this.cycleIndex--;
          break
        case 'down':
          this.cycleIndex++;
          break
      }

      if (this.cycleIndex > (this.activeSuggestions.length - 1)) {
        this.cycleIndex = 0;
      }
      if (this.cycleIndex < 0) {
        this.cycleIndex = (this.activeSuggestions.length - 1);
      }

    },
    chooseSelection() {
      if (this.activeSuggestions.length > 0) {
        this.command = this.activeSuggestions[this.cycleIndex].command;
        this.go();
      }
    },
    forceStartTimer(name) {
      let data = {
        trackable_type: 'firm',
        trackable_uuid: this.user.current_account.firm.uuid,
        name: name,
        start_date: this.$dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      };
      timeEntryAPI.create(data)
        .then(rsp => {
          this.$nuxt.$authService.refreshState().then(rsp => {
            this.$nuxt.$emit('notification', 'New timer started');
          });
        })
    }
  }
}
