//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex"

export default {
  name: "BrandingColoursCSS",
  data() {
    return {
    }
  },
  methods: {
    convertHex (color, opacity) {
      color = color.replace('#', '')
      const r = parseInt(color.substring(0, 2), 16)
      const g = parseInt(color.substring(2, 4), 16)
      const b = parseInt(color.substring(4, 6), 16)
      return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`
    }
  },
  computed: {
    ...mapState('user',['firm'])
  }
}
