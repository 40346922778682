//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "BooleanButtonChoice",
  props: {
    value: {
      type: Boolean | null,
      required: true,
    },
    button_options: {
      type: Object | null,
      required: false
    }
  },
  methods: {
    bubbleUp(v) {
      this.$emit('input', v)
    }
  },
  data(){
    return {
      options: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false,
        }
      ]
    }
  },

  mounted() {
    if(this.button_options) {
      this.options = this.button_options;
    }
  }
}
