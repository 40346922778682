var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.queue.length > 0,
      expression: "queue.length > 0"
    }]
  }, [_c('div', {
    staticClass: "fixed bottom-0 w-full z-50 flex space-x-4 py-2 px-4 bg-p-navy-light overflow-x-auto border-t-4 border-p-yellow"
  }, _vm._l(_vm.queue, function (file) {
    return _c('ui-file-tile', {
      key: file.uuid,
      attrs: {
        "file": file,
        "thickBorder": true
      }
    });
  }), 1), _vm._v(" "), _c('file-pond', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "filePond",
    attrs: {
      "name": "filepond",
      "allow-multiple": true,
      "server": _vm.serverConfiguration,
      "instant-upload": false,
      "chunk-uploads": true,
      "chunk-force": true,
      "chunk-size": 5242880,
      "allow-revert": true,
      "disabled": true,
      "allow-drop": false,
      "allow-browse": false,
      "allow-paste": false,
      "labelIdle": "Plannr File Uploader",
      "allow-process": true,
      "allow-file-metadata": true,
      "max-file-size": "5000MB",
      "chunk-retry-delays": [],
      "accepted-file-types": _vm.validFileTypes,
      "allow-file-type-validation": false
    },
    on: {
      "addfile": _vm.addFile,
      "processfile": _vm.processFile,
      "processfileprogress": _vm.processFileProgress
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }