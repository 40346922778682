import paginator from "@/helpers/paginator";

export default {
  allEmployees: async (data = null) => await paginator.grabAllPages($nuxt.$api.host + '/employee', {}, data),
  getEmployees: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/employee', data),
  createEmployee: (data) => $nuxt.$axios.post($nuxt.$api.host + '/employee', data),
  updateEmployee: (employee_uuid, data = null) => $nuxt.$axios.put($nuxt.$api.host + '/employee/' + employee_uuid, data),
  getEmployee: (employee_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/employee/' + employee_uuid, data),
  deleteEmployee: (employee_uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/employee/' + employee_uuid),
}
