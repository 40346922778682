var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.avatar_url !== null ? _c('div', {
    staticClass: "rounded-md overflow-hidden"
  }, [_c('img', {
    class: _vm.classes,
    attrs: {
      "src": _vm.avatar_url,
      "alt": ""
    }
  })]) : _c('vue-avatar', {
    class: _vm.classes,
    attrs: {
      "username": _vm.name,
      "size": _vm.size,
      "rounded": _vm.rounded
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }