var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "shortkey",
      rawName: "v-shortkey",
      value: ['esc'],
      expression: "['esc']"
    }],
    on: {
      "shortkey": function ($event) {
        return _vm.reset();
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_c('div', {
    directives: [{
      name: "shortkey",
      rawName: "v-shortkey",
      value: ['/'],
      expression: "['/']"
    }, {
      name: "show",
      rawName: "v-show",
      value: _vm.opened,
      expression: "opened"
    }],
    staticClass: "w-screen h-screen fixed top-0 left-0 backdrop-filter backdrop-blur-sm bg-branding-sidebar-background-opacity text-p-aqua px-4 md:p-12 text-lg flex flex-row items-center",
    on: {
      "shortkey": function ($event) {
        return _vm.open();
      }
    }
  }, [_c('a', {
    staticClass: "text-white absolute right-2 top-2 opacity-50 hover:opacity-100 ease-in-out duration-300 transition-all cursor-pointer",
    on: {
      "click": function ($event) {
        return _vm.reset();
      }
    }
  }, [_c('svg', {
    staticClass: "w-12 h-12",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.5",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "d": "M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    }
  })])]), _vm._v(" "), _c('div', {
    directives: [{
      name: "shortkey",
      rawName: "v-shortkey",
      value: ['shift', 'enter'],
      expression: "['shift', 'enter']"
    }],
    staticClass: "w-full md:w-4/6 md:mx-auto",
    on: {
      "shortkey": _vm.chooseSelection
    }
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('h1', {
    staticClass: "text-p-yellow font-bold mb-3 flex flex-row items-center"
  }, [_c('svg', {
    staticClass: "w-8 h-8 mr-3",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.5",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "d": "M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
    }
  })]), _vm._v(" "), _c('span', [_vm._v("RocketBar")])]), _vm._v(" "), _c('form', {
    staticClass: "w-full transition-all ease-in-out duration-150",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.go.apply(null, arguments);
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.command,
      expression: "command"
    }, {
      name: "shortkey",
      rawName: "v-shortkey",
      value: {
        up: ['arrowup'],
        down: ['arrowdown']
      },
      expression: "{up: ['arrowup'], down: ['arrowdown']}"
    }],
    ref: "commandSearch",
    staticClass: "w-full bg-white border-white focus:outline-none p-4 rounded-md text-xl text-p-navy",
    class: {
      'rounded-b-none': _vm.activeSuggestions.length > 0 && _vm.command.length > 0
    },
    attrs: {
      "placeholder": "Type help for options"
    },
    domProps: {
      "value": _vm.command
    },
    on: {
      "shortkey": _vm.cycleOptions,
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.command = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.activeSuggestions.length > 0 && _vm.command.length > 0 ? _c('div', {
    staticClass: "p-2 rounded-md bg-p-navy border border-white border-2 transition-all ease-in-out duration-150 rounded-t-none space-y-1 max-h-80 md:max-h-96 overflow-y-auto"
  }, _vm._l(_vm.activeSuggestions, function (suggestion, index) {
    return _c('div', {
      staticClass: "flex flex-row items-center justify-between transition-all ease-in-out duration-300 p-2 px-3 border-2 border-transparent cursor-pointer hover:border-2 hover:bg-p-navy-light rounded-md",
      class: {
        'bg-p-navy-light shadow-lg': index === _vm.cycleIndex
      },
      on: {
        "click": function ($event) {
          _vm.command = suggestion.command;
        }
      }
    }, [_c('div', {
      staticClass: "flex flex-col"
    }, [_c('ui-title', {
      staticClass: "text-white font-bold mb-0",
      attrs: {
        "size": "x-small"
      }
    }, [_vm._v(_vm._s(suggestion.command) + "\n                  ")]), _vm._v(" "), suggestion.description ? _c('ui-text', {
      staticClass: "text-white opacity-50",
      attrs: {
        "size": "x-small"
      }
    }, [_vm._v("\n                    " + _vm._s(suggestion.description) + "\n                  ")]) : _vm._e()], 1), _vm._v(" "), _c('div', [_c('p', {
      staticClass: "border-p-aqua border box-border text-p-aqua py-1 px-3 text-xs rounded-full",
      class: {
        'bg-white text-p-navy border-none': index === _vm.cycleIndex
      }
    }, [_vm._v("shift + enter")])])]);
  }), 0) : _vm._e()])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }