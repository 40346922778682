export default {
  state: () => ({
    intents: {},
  }),

  actions: {
    addIntent({commit}, intention) {
      commit('ADD_INTENT', intention);
      $nuxt.$emit('hasIntent-' + intention);
    },
    async checkIntent({ state, commit }, {intention, callback}) {
      const exists = state.intents[intention] !== undefined;
      const data = state.intents[intention];

      if (exists === false) {
        return;
      }

      await commit('DELETE_INTENT', intention);

      callback(data);
    }
  },

  mutations: {
    ADD_INTENT(state, intention) {
      state.intents[intention.intention] = intention.data;
    },
    DELETE_INTENT(state, intention) {
      delete state.intents[intention];
    },
  },

  namespaced: true,
}
