//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Navigation',

  data() {
    return {
      items: [
        {
          to: '/dashboard',
          icon: 'home',
          text: 'Dashboard',
          children: [
            {
              to: '/dashboard',
              text: 'My overview',
              restricted: [],
            },
            {
              to: '/dashboard/my-logged-time',
              text: 'My logged time',
              restricted: [],
            },
            {
              to: '/dashboard/my-tasks',
              text: 'My tasks',
              restricted: [],
            },
          ],
          restricted: [],
        },
        {
          to: '/clients',
          icon: 'clients',
          text: 'Clients',
          children: [
            {
              to: '/clients',
              text: 'Clients',
              restricted: [],
            },
            {
              to: '/prospects',
              text: 'Prospects',
              restricted: [],
            },
            {
              to: '/plans',
              text: 'Plans',
              restricted: [],
            },
          ],
          restricted: [],
        },
        {
          to: '/conversations',
          icon: 'chat',
          text: 'Conversations',
          restricted: [],
        },
        {
          to: '/cases',
          icon: 'cases',
          text: 'Cases',
          restricted: [],
        },
        {
          to: '/business/key-metrics',
          icon: 'excel',
          text: 'Business',
          children: [
            {
              to: '/business/introducers',
              text: 'Introducers',
              restricted: [],
            },
            {
              to: '/automations',
              text: 'Automations',
              restricted: [],
            },
            {
              to: '/business/docs',
              text: 'Document Builder',
              restricted: [],
            },
            {
              to: '/business/signatures',
              text: 'Signature Requests',
              restricted: [],
            },
            {
              to: '/business/income-reconciliation',
              text: 'Income Reconciliation',
              restricted: ['owners', 'admins'],
            },
            {
              to: '/business/reporting',
              text: 'Reporting',
              restricted: ['owners', 'admins'],
            },
            {
              to: '/business/imports',
              text: 'Imports',
              restricted: [],
            },

          ],
          restricted: [],
        },
        {
          to: '/settings',
          icon: 'gear',
          text: 'Settings',
          restricted: ['owners', 'admins'],
        },
      ],
      clientItems: [
        {
          to: '/c',
          icon: 'home',
          text: 'Dashboard',
        },
        {
          to: '/c/my-details',
          icon: 'person',
          text: 'About Me',
        },
        {
          to: '/c/conversations',
          icon: 'chat',
          text: 'Conversations',
        },
        {
          to: '/c/documents',
          icon: 'folder',
          text: 'Documents',
        },
        {
          to: '/c/my-tasks',
          icon: 'square-tick',
          text: 'My Tasks',
        },
        {
          to: '/c/my-wealth',
          icon: 'bar-chart',
          text: 'My Wealth',
        },
      ],
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isClient', 'isEmployee', 'isOwner', 'isAdmin', 'isMember', 'isNotSeniorEmployee']),

    navItems() {
      return this.items.filter((parent) => this.filterLinks(parent))
    },
  },

  methods: {
    filterLinks(item) {
      if (!item.hasOwnProperty('restricted') || !Array.isArray(item.restricted)) {
        return true
      }

      if (item.restricted.some((r) => ['owners', 'admins'].includes(r)) && this.isNotSeniorEmployee) {
        return false
      }

      if (item.hasOwnProperty('children') && Array.isArray(item.children)) {
        item.children = item.children.filter((child) => this.filterLinks(child))
      }

      return true
    },
  },
}
