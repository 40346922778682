var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ui-form-field', {
    class: _vm.labelSize,
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(_vm._s(_vm.label))];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c('div', {
    staticClass: "w-full",
    class: {
      'multiselect-small': _vm.small
    }
  }, [_c('multiselect', {
    attrs: {
      "options": _vm.data,
      "multiple": true,
      "loading": false,
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true,
      "placeholder": _vm.label,
      "label": "name",
      "track-by": "uuid",
      "selectLabel": _vm.selectLabel,
      "deselectLabel": _vm.deselectLabel
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('avatar', {
          staticClass: "option__image rounded-full overflow-hidden w-8 h-8",
          attrs: {
            "name": props.option.name,
            "size": 32
          }
        }), _vm._v(" "), _c('div', [_c('div', {
          staticClass: "option__desc ml-4"
        }, [_c('span', {
          staticClass: "option__title"
        }, [_vm._v(_vm._s(props.option.name))])]), _vm._v(" "), _c('ui-text', {
          staticClass: "ml-4 -mt-3 capitalize text-p-gray-4",
          attrs: {
            "size": "small"
          }
        }, [_vm._v(_vm._s(props.option.type))])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.selectedList,
      callback: function ($$v) {
        _vm.selectedList = $$v;
      },
      expression: "selectedList"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }