//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import clientAPI from '../api/clients';

export default {
  name: 'ClientSelector',

  props: {
    value: {
      type: String | null,
      required: true,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: 'Select client',
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: {},
      clientList: [],
      loading: true,
    };
  },

  created() {
    this.grabClients();
  },

  methods: {
    grabClients() {
      clientAPI.getAllClients().then((rsp) => {
        this.clientList = rsp;

        if (this.value !== null) {
          this.selected = this.clientList.filter((item) => {
            return this.value === item.uuid;
          });
        }

        this.loading = false;
      });
    },
  },

  watch: {
    selected: {
      deep: true,
      handler(new_val) {
        if (new_val !== null) {
          if (new_val instanceof Array) {
            this.$emit('input', this.returnObject ? new_val[0] : new_val[0].uuid);
          } else {
            this.$emit('input', this.returnObject ? new_val : new_val.uuid);
          }
        } else {
          this.$emit('input', null);
        }
      },
    },
  },
};
