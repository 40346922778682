import paginator from "@/helpers/paginator";

export default {

  // Monthly Statements

  getAllStatements: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/statement', data),
  getStatement: (uuid) => $nuxt.$axios.get($nuxt.$api.host + '/statement/' + uuid),
  lockStatement: (uuid) => $nuxt.$axios.post($nuxt.$api.host + '/statement/' + uuid + '/lock'),
  unlockStatement: (uuid) => $nuxt.$axios.post($nuxt.$api.host + '/statement/' + uuid + '/unlock'),

  // Charges (Schedules)

  createCharge: (data) => $nuxt.$axios.post($nuxt.$api.host + '/charge', data),
  getCharge: (uuid) => $nuxt.$axios.get($nuxt.$api.host + '/charge/' + uuid),
  getClientCharges: (uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/client/' + uuid + '/charges', data),
  getPlanCharges: (uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/plans/' + uuid + '/charges', data),
  updateCharge: (uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/charge/' + uuid, data),
  deleteCharge: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/charge/' + uuid),

  // Expectations

  createExpectation: (data) => $nuxt.$axios.post($nuxt.$api.host + '/expected-charge', data),
  getExpectation: (uuid) => $nuxt.$axios.get($nuxt.$api.host + '/expected-charge/' + uuid),
  getClientExpectations: (uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/client/' + uuid + '/expected-charges', data),
  getPlanExpectations: (uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/plans/' + uuid + '/expected-charges', data),
  getExpectations: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/expected-charge', data),
  updateExpectation: (uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/expected-charge/' + uuid, data),
  deleteExpectation: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/expected-charge/' + uuid),
  reconcileExpectation: (data) => $nuxt.$axios.post($nuxt.$api.host + '/expected-charge/reconcile', data),
  calculate: (data) => $nuxt.$axios.post($nuxt.$api.host + '/expected-charge/calculate', data),
  bulkCreateExpectations: (data) => $nuxt.$axios.post($nuxt.$api.host + '/expected-payment/create-expected-charges', data),
  balanceExpectations: (data) => $nuxt.$axios.post($nuxt.$api.host + '/expected-charge/balance', data),

  // Provider Statements

  createProviderStatement: (data) => $nuxt.$axios.post($nuxt.$api.host + '/provider-statement', data),
  getProviderStatements: (data) => $nuxt.$axios.get($nuxt.$api.host + '/provider-statement', data),
  getProviderStatement: (uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/provider-statement/' + uuid, data),
  updateProviderStatement: (uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/provider-statement/' + uuid, data),
  deleteProviderStatement: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/provider-statement/' + uuid),
  uploadProviderStatement: (data) => $nuxt.$axios.post($nuxt.$api.host + '/provider-statement/upload', data),

  // Provider Statement Transactions

  getProviderStatementTransactions: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/expected-payment', data),
  createProviderStatementTransaction: (data) => $nuxt.$axios.post($nuxt.$api.host + '/expected-payment', data),
  getProviderStatementTransaction: (uuid) => $nuxt.$axios.get($nuxt.$api.host + '/expected-payment/' + uuid),
  updateProviderStatementTransaction: (uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/expected-payment/' + uuid, data),
  deleteProviderStatementTransaction: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/expected-payment/' + uuid),
  reconcileProviderStatementTransaction: (uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/expected-payment/' + uuid + '/reconcile', data),
  unreconcileExpectationFromTransaction: (uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/expected-payment/' + uuid + '/unreconcile-expectation', data),

  // Bank Transactions

  getBankTransactions: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/payment', data),
  createBankTransaction: (data) => $nuxt.$axios.post($nuxt.$api.host + '/payment', data),
  getBankTransaction: (uuid) => $nuxt.$axios.get($nuxt.$api.host + '/payment/' + uuid),
  updateBankTransaction: (uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/payment/' + uuid, data),
  deleteBankTransaction: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/payment/' + uuid),
  importBankTransactionsCSV: (data) => $nuxt.$axios.post($nuxt.$api.host + '/payment/csv', data),
  reconcileBankTransaction: (data) => $nuxt.$axios.post($nuxt.$api.host + '/payment/reconcile', data),

  // Reconciliations

  deleteReconciliation: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/reconciliation/' + uuid),
}
