//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "FileActions",
  data() {
    return {
      isOpen: false,
    }
  }
}
