var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-row-reverse space-x-3",
    on: {
      "mouseover": function ($event) {
        _vm.isOpen = true;
      },
      "mouseleave": function ($event) {
        _vm.isOpen = false;
      }
    }
  }, [_c('div', {
    staticClass: "cursor-pointer ml-3",
    on: {
      "click": function ($event) {
        _vm.isOpen = true;
      }
    }
  }, [_c('ui-icon-three-dots-vertical', {
    staticClass: "w-5 h-5",
    attrs: {
      "type": "solid"
    }
  })], 1), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_vm.isOpen ? _c('div', {
    staticClass: "transition-all ease-in-out duration-300 bg-white flex flex-row space-x-3"
  }, [_vm._t("default")], 2) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }