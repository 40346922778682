//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "result-icon",
  props: {
    result: {
      type: Object,
      required: true,
    }
  },
  data: () => {
    return {
      known: ['client', 'employee', 'note', 'address', 'message', 'case', 'conversation', 'file', 'folder', 'plan']
    }
  }
}
