var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex items-center space-x-4 flex-shrink-0 mx-4 mt-4 p-4 rounded-md bg-p-navy-light bg-branding-sidebar-title-background"
  }, [_vm.firm.logo ? _c('div', [_c('img', {
    attrs: {
      "src": _vm.firm.logo,
      "alt": "Firm Logo"
    }
  })]) : _c('div', {
    staticClass: "flex flex-row items-center"
  }, [_c('ui-small-logo'), _vm._v(" "), _c('div', {
    staticClass: "ml-3"
  }, [_c('p', {
    staticClass: "text-p-yellow text-xl leading-none text-branding-sidebar-title"
  }, [_vm._v(_vm._s(_vm.firm.name))]), _vm._v(" "), _c('ui-text', {
    staticClass: "leading-none text-white text-branding-sidebar-sub-title",
    attrs: {
      "size": "x-small"
    }
  }, [_vm._v("plannrcrm.com")])], 1)], 1), _vm._v(" "), _vm.accounts.length > 1 ? _c('div', [_c('ui-icon-three-dots-vertical', {
    staticClass: "w-5 h-5 text-white"
  })], 1) : _vm._e()]), _vm._v(" "), _vm.currentLogin.write === false ? _c('ui-text', {
    staticClass: "flex justify-center mt-1 -mb-6 text-p-gray text-branding-sidebar-sub-title",
    attrs: {
      "size": "xx-small"
    }
  }, [_vm._v("Read Only Account\n  ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }