var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.appLoading === false ? _c('div', {
    ref: "app",
    staticClass: "min-h-screen flex overflow-y-auto bg-p-gray-8"
  }, [_c('app-notification-event-listener'), _vm._v(" "), _c('BrandingColoursCSS'), _vm._v(" "), _vm.menuOpen ? _c('div', {
    ref: "mobileMenuWrapper",
    staticClass: "fixed inset-0 flex z-40 lg:hidden",
    attrs: {
      "role": "dialog",
      "aria-modal": "true"
    }
  }, [_c('div', {
    staticClass: "fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity ease-linear duration-300",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.closeMenu.apply(null, arguments);
      }
    }
  }), _vm._v(" "), _c('div', {
    ref: "mobileMenu",
    staticClass: "relative flex-1 flex flex-col max-w-xs w-full bg-p-navy bg-branding-sidebar-background"
  }, [_c('div', {
    staticClass: "absolute top-0 right-0 -mr-12 pt-2 ease-in-out duration-300"
  }, [_c('button', {
    staticClass: "ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.closeMenu
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Close sidebar")]), _vm._v(" "), _c('svg', {
    staticClass: "h-6 w-6 text-white",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M6 18L18 6M6 6l12 12"
    }
  })])])]), _vm._v(" "), _c('div', {
    staticClass: "flex-1 h-0 pb-4 overflow-y-auto"
  }, [_c('AccountSwitcher'), _vm._v(" "), _c('nav', {
    staticClass: "mt-5 space-y-1"
  }, [_c('navigation')], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "flex-shrink-0 flex border-t p-4 bg-branding-sidebar-title-background"
  }, [_c('div', {
    staticClass: "flex-shrink-0 w-full group block"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', [_c('avatar', {
    staticClass: "inline-block h-6 w-6 rounded border-2 border-white",
    attrs: {
      "name": _vm.user.name,
      "size": 20
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "ml-3 mr-auto"
  }, [_c('ui-text', {
    staticClass: "font-bold text-p-yellow text-branding-sidebar-title",
    attrs: {
      "size": "small"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.user.name) + "\n              ")]), _vm._v(" "), _c('p', {
    staticClass: "truncate text-xs font-medium text-indigo-200 text-branding-sidebar-sub-title group-hover:text-branding-sidebar-sub-title-hover"
  }, [_vm._v("\n                " + _vm._s(_vm.user.email) + "\n              ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "ml-3 cursor-pointer transform ease-in-out text-branding-sidebar-sub-title duration-300 hover:text-branding-sidebar-title hover:-translate-y-1",
    on: {
      "click": _vm.toggleAccountBlock
    }
  }, [_c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "12",
      "cy": "6",
      "r": "2",
      "fill": "currentColor"
    }
  }), _vm._v(" "), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "12",
      "r": "2",
      "fill": "currentColor"
    }
  }), _vm._v(" "), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "18",
      "r": "2",
      "fill": "currentColor"
    }
  })])])]), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "home"
    }
  }, [_vm.showAccountBlock ? _c('div', {
    staticClass: "bg-branding-sidebar-background divide-y divide-p-navy-light text-sm -mx-4 -mb-4 mt-4 text-white flex flex-col"
  }, [_c('nuxt-link', {
    staticClass: "items-center cursor-pointer py-3 px-4 hover:bg-white hover:bg-opacity-10 flex flex-row space-x-3",
    attrs: {
      "to": "/settings/account-details"
    }
  }, [_c('ui-icon-person', {
    staticClass: "w-4 h-4"
  }), _vm._v(" "), _c('span', [_vm._v("My account")])], 1), _vm._v(" "), _c('a', {
    staticClass: "flex flex-row space-x-3 items-center cursor-pointer py-3 px-4 hover:bg-white hover:bg-opacity-10",
    attrs: {
      "href": "https://plannr.canny.io/plannr-crm",
      "target": "_blank"
    }
  }, [_vm._v("\n                Roadmap\n              ")]), _vm._v(" "), _c('a', {
    staticClass: "flex flex-row space-x-3 items-center cursor-pointer py-3 px-4 hover:bg-white hover:bg-opacity-10",
    on: {
      "click": function ($event) {
        return _vm.$authService.logout();
      }
    }
  }, [_c('ui-icon-lock', {
    staticClass: "w-4 h-4"
  }), _vm._v(" "), _c('span', [_vm._v("Log out")])], 1)], 1) : _vm._e()])], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "flex-shrink-0 w-14",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0 bg-p-navy bg-branding-sidebar-background"
  }, [_c('div', {
    staticClass: "flex-1 flex-col min-h-0"
  }, [_c('div', {
    staticClass: "h-full"
  }, [_c('div', {
    staticClass: "flex h-full flex-col overflow-y-auto"
  }, [_c('AccountSwitcher'), _vm._v(" "), _c('nav', {
    staticClass: "flex-1 mt-5 space-y-1"
  }, [_c('div', {
    staticClass: "flex flex-col justify-between items-center"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('Navigation')], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "transition-all flex-shrink-0 flex ease-in-out duration-300 p-4 bg-branding-sidebar-title-background"
  }, [_c('div', {
    staticClass: "flex-shrink-0 w-full group block"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', [_c('avatar', {
    staticClass: "inline-block h-6 w-6 rounded border-2 border-white",
    attrs: {
      "name": _vm.user.name,
      "size": 20
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "ml-3 w-48 truncate text-ellipsis"
  }, [_c('ui-text', {
    staticClass: "font-bold text-p-yellow text-branding-sidebar-title",
    attrs: {
      "size": "small"
    }
  }, [_vm._v("\n                    " + _vm._s(_vm.user.name) + "\n                  ")]), _vm._v(" "), _c('p', {
    staticClass: "text-ellipsis truncate text-xs font-medium text-indigo-200 text-branding-sidebar-sub-title"
  }, [_vm._v("\n                    " + _vm._s(_vm.user.email) + "\n                  ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "ml-3 cursor-pointer transform ease-in-out text-branding-sidebar-sub-title duration-300 hover:text-branding-sidebar-title hover:-translate-y-1",
    on: {
      "click": _vm.toggleAccountBlock
    }
  }, [_c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "12",
      "cy": "6",
      "r": "2",
      "fill": "currentColor"
    }
  }), _vm._v(" "), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "12",
      "r": "2",
      "fill": "currentColor"
    }
  }), _vm._v(" "), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "18",
      "r": "2",
      "fill": "currentColor"
    }
  })])])]), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "home"
    }
  }, [_vm.showAccountBlock ? _c('div', {
    staticClass: "bg-branding-sidebar-background divide-y divide-p-navy-light text-sm -mx-4 -mb-4 mt-4 text-white flex flex-col"
  }, [_c('nuxt-link', {
    staticClass: "items-center cursor-pointer py-3 px-4 hover:bg-white hover:bg-opacity-10 flex flex-row space-x-3",
    attrs: {
      "to": "/settings/account-details"
    }
  }, [_c('ui-icon-person', {
    staticClass: "w-4 h-4"
  }), _vm._v(" "), _c('span', [_vm._v("My account")])], 1), _vm._v(" "), _c('a', {
    staticClass: "flex flex-row space-x-3 items-center cursor-pointer py-3 px-4 hover:bg-white hover:bg-opacity-10",
    attrs: {
      "href": "https://plannr.canny.io/plannr-crm",
      "target": "_blank"
    }
  }, [_c('ui-icon-voice', {
    staticClass: "w-4 h-4"
  }), _vm._v(" "), _c('span', [_vm._v("Roadmap")])], 1), _vm._v(" "), _c('a', {
    staticClass: "flex flex-row space-x-3 items-center cursor-pointer py-3 px-4 hover:bg-white hover:bg-opacity-10",
    on: {
      "click": function ($event) {
        return _vm.$authService.logout();
      }
    }
  }, [_c('ui-icon-lock', {
    staticClass: "w-4 h-4"
  }), _vm._v(" "), _c('span', [_vm._v("Log out")])], 1)], 1) : _vm._e()])], 1)])], 1)])])]), _vm._v(" "), _c('div', {
    staticClass: "lg:pl-64 flex flex-col w-0 flex-1 overflow-hidden"
  }, [_c('div', {
    staticClass: "lg:hidden px-1 py-1 sm:pl-3 sm:pt-3 flex flex-row justify-between relative"
  }, [_c('button', {
    staticClass: "-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openMenu
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Open sidebar")]), _vm._v(" "), _c('svg', {
    staticClass: "h-6 w-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M4 6h16M4 12h16M4 18h16"
    }
  })])]), _vm._v(" "), _c('div', {
    staticClass: "lg:hidden mr-2 mt-1 sm:mt-0"
  }, [_c('header-search-panel', {
    attrs: {
      "naked": true
    }
  })], 1)]), _vm._v(" "), _c('search-block'), _vm._v(" "), _c('notification'), _vm._v(" "), _c('main', {
    staticClass: "flex-1 relative z-0 overflow-y-auto focus:outline-none"
  }, [_c('Nuxt')], 1), _vm._v(" "), _c('file-uploader', {
    ref: "fileUploader"
  }), _vm._v(" "), _c('vue-confirm-dialog'), _vm._v(" "), _c('rocket-bar'), _vm._v(" "), _c('modals-create-note', {
    ref: "noteModal"
  }), _vm._v(" "), _c('modals-create-task-from-anywhere', {
    ref: "createTaskFromAnywhere"
  })], 1), _vm._v(" "), _c('script', [_vm._v("!function (w, d, i, s) {\n    function l() {\n      if (!d.getElementById(i)) {\n        var f = d.getElementsByTagName(s)[0], e = d.createElement(s);\n        e.type = \"text/javascript\", e.async = !0, e.src = \"https://canny.io/sdk.js\", f.parentNode.insertBefore(e, f)\n      }\n    }\n\n    if (\"function\" != typeof w.Canny) {\n      var c = function () {\n        c.q.push(arguments)\n      };\n      c.q = [], w.Canny = c, \"complete\" === d.readyState ? l() : w.attachEvent ? w.attachEvent(\"onload\", l) : w.addEventListener(\"load\", l, !1)\n    }\n  }(window, document, \"canny-jssdk\", \"script\");")])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }