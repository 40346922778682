//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import clientAPI from "../api/clients";
import hasCurrentFirmUuid from "../mixins/hasCurrentFirmUuid";
import caseStatus from '~/api/caseStatus';
import casesAPI from "../api/cases";

export default {
  name: "ClientCasesMultiSelect",

  mixins: [
    hasCurrentFirmUuid
  ],

  props: {
    client_uuid: {
      type: String | null,
      required: false,
      default: null
    },
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      selectedList: [],
      caseList: [],
      loading: true,
    }
  },

  created() {
    this.grabCases();
  },

  methods: {

    grabCases() {
      if (this.client_uuid == null) {
        return;
      }
      casesAPI.getClientCases(this.client_uuid)
      .then(rsp => {
        this.caseList = rsp.data.data;

        if( this.value ) {
          this.selectedList = this.caseList.filter((item) => {
            if( Array.isArray(this.value) ) {
              return this.value.includes(item.uuid)
            } else {
              return this.value.uuid === item.uuid;
            }
          })
        }

        this.loading = false;

      })
    },
  },

  watch: {
    client_uuid(new_val){
      this.grabCases();
    },

    selectedList(new_val) {
      if( !Array.isArray(new_val) ){
        this.$emit('input', new_val.uuid);
        return;
      }

      let reduced = new_val.map(item => item.uuid);

      this.$emit('input', reduced);
    }
  }
}
