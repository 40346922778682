import { render, staticRenderFns } from "./NavSection.vue?vue&type=template&id=2504db74&"
import script from "./NavSection.vue?vue&type=script&lang=js&"
export * from "./NavSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavItem: require('/vercel/path0/components/NavItem.vue').default})
