//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import boardAPI from '@/api/boards';

export default {
  name: "ClientTaskSelect",

  props: {
    value: {
      type: String | null,
      required: true,
    },
    boardUuid: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    }
  },
  mounted() {
    this.getTasks(this.boardUuid)
  },

  data() {
    return {
      selectedList: [],
      tasks: [],
      loading: true,
      board: {
        lists: []
      },
    }
  },

  methods: {
    getTasks(board_uuid) {

      boardAPI.getAllTasksFromBoard(board_uuid)
        .then(rsp => {
          this.tasks = rsp;

          if (this.value !== null) {
            this.selectedList = this.tasks.filter((item) => {
              return this.value === item.uuid;
            })
          }

          this.loading = false;
        })

    }

  },

  watch: {
    selectedList(new_val) {
      if (!Array.isArray(new_val)) {
        this.$emit('input', new_val.uuid);
        return;
      }

      let reduced = new_val.map(item => item.uuid);

      this.$emit('input', reduced);
    },
    boardUuid(new_val) {
      this.getTasks(new_val)
    }
  }
}
