var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.appLoading === false ? _c('div', {
    staticClass: "min-h-screen flex flex-col justify-center min-h-screen py-12 bg-p-gray-light sm:px-6 lg:px-8"
  }, [_c('div', {
    staticClass: "sm:mx-auto sm:w-full sm:max-w-md"
  }, [_c('Nuxt')], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }