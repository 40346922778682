import paginator from "@/helpers/paginator";

export default {
  getBoard: (board_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/board/' + board_uuid, data),
  getTasks: (board_uuid, list_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/board/' + board_uuid + '/list/' + list_uuid + '/task/', data),
  addTask: (board_uuid, list_uuid, data = null) => $nuxt.$axios.post($nuxt.$api.host + '/board/' + board_uuid + '/list/' + list_uuid + '/task/', data),
  updateTask: (board_uuid, list_uuid, task_uuid, data = null) => $nuxt.$axios.put($nuxt.$api.host + '/board/' + board_uuid + '/list/' + list_uuid + '/task/' + task_uuid, data),
  deleteTask: (board_uuid, list_uuid, task_uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/board/' + board_uuid + '/list/' + list_uuid + '/task/' + task_uuid),
  addList: (board_uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/board/' + board_uuid + '/list', data),
  updateList: (board_uuid, list_uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/board/' + board_uuid + '/list/' + list_uuid, data),
  deleteList: (board_uuid, list_uuid, data) => $nuxt.$axios.delete($nuxt.$api.host + '/board/' + board_uuid + '/list/' + list_uuid),
  getTask: (board_uuid, list_uuid, task_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/board/' + board_uuid + '/list/' + list_uuid + '/task/' + task_uuid),
  getTasksFromBoard: (board_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/board/' + board_uuid + '/tasks', data),
  getAllTasksFromBoard: async (board_uuid, data = null) => await paginator.grabAllPages($nuxt.$api.host + '/board/' + board_uuid + '/tasks', {}, data),
  getTasksFirmLevel: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/tasks/', data),
}
