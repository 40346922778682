import paginator from "@/helpers/paginator";

export default {
  getAllCases: async (data = null) => await paginator.grabAllPages($nuxt.$api.host + '/cases', {}, data),
  getCases: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/cases', data),
  createCase: (data) => $nuxt.$axios.post($nuxt.$api.host + '/cases', data),
  getClientCases: (client_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/client/' + client_uuid + '/cases', data),
  getCase: (case_uuid, data) => $nuxt.$axios.get($nuxt.$api.host + '/cases/' + case_uuid, data),
  updateCase: (case_uuid, data = null) => $nuxt.$axios.put($nuxt.$api.host + '/cases/' + case_uuid, data),
  removeCase: (case_uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/cases/' + case_uuid),
  getAllCaseConversations: async (case_uuid, data = null) => await paginator.grabAllPages($nuxt.$api.host + '/cases/' + case_uuid + '/conversations', {}, data),
  getCaseConversations:  (case_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/cases/' + case_uuid + '/conversations', data),
  getDocuments: (case_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/cases/' + case_uuid + '/documents'),
  addUsersToCase: (case_uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/cases/' + case_uuid + '/participants', data),
  removeFromCase: (case_uuid, data) => $nuxt.$axios.delete($nuxt.$api.host + '/cases/' + case_uuid + '/participants', {data: data}),
}
