var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ui-sidebar-modal', {
    ref: "timetracking",
    attrs: {
      "has_header": true,
      "has_icon": ""
    },
    scopedSlots: _vm._u([{
      key: "header_title",
      fn: function () {
        return [_vm._v("\n      Timer\n    ")];
      },
      proxy: true
    }, {
      key: "header_icon",
      fn: function () {
        return [_c('ui-icon-clock', {
          staticClass: "w-12 h-12",
          class: {
            'animate-spin-slow text-p-green': _vm.user.current_account.current_time_entry,
            'text-p-gray-7': _vm.user.current_account.current_time_entry === null
          },
          attrs: {
            "type": "outline"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _vm._v(" "), _c('new-timer', {
    ref: "newTimer",
    attrs: {
      "timer": _vm.currentTimer
    }
  }), _vm._v(" "), _c('recent-timer-entries', {
    attrs: {
      "running-timer": this.user.current_account.current_time_entry,
      "editable": false
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }