import { render, staticRenderFns } from "./create-task-from-anywhere.vue?vue&type=template&id=79c9da88&"
import script from "./create-task-from-anywhere.vue?vue&type=script&lang=js&"
export * from "./create-task-from-anywhere.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Avatar: require('/vercel/path0/components/Avatar.vue').default,ParticipantSelector: require('/vercel/path0/components/ParticipantSelector.vue').default,CustomField: require('/vercel/path0/components/CustomField.vue').default,ErrorBlockFrontEnd: require('/vercel/path0/components/ErrorBlockFrontEnd.vue').default,ErrorBlock: require('/vercel/path0/components/ErrorBlock.vue').default})
