const {v4: uuidv4} = require('uuid');

export default {
  state: () => ({
    board: {
      uuid: '',
      lists: []
    },
  }),
  getters: {},
  mutations: {

    ADD_BOARD(state, payload){

      state.board = payload.board

    },

    DELETE_BOARD(state){
      delete state.board.uuid;
      delete state.board.lists;
    },

    REMOVE_LIST(state, payload) {
      let listIndex = payload.listIndex;
      state.board.lists.splice(listIndex, 1);
    },
    ADD_LIST(state, payload) {
      state.board.lists.push({
        name: payload.list.name,
        uuid: payload.list.uuid,
        items: []
      });
    },
    editListName(state, payload) {
      let listIndex = payload.listIndex;
      let itemName = payload.newName;
      Vue.set(state.lists[listIndex], 'name', itemName);
    },
    updateListPosition(state, payload) {
      let lists = payload.lists;
      state.lists = lists;
    },

    addItem(state, payload) {
      let listIndex = payload.listIndex;
      let item = payload.item;
      state.lists[listIndex].items.push({
        name: item,
        id: uuidv4(),
      });
    },
  },
  actions: {
    tasksRemoveList({commit, state}, payload) {

      let list_uuid = payload.list_uuid;
      let listIndex = state.board.lists.findIndex(el => el.uuid === list_uuid)

      if (listIndex > -1) {
        commit('REMOVE_LIST', {listIndex});
      }
    },
    tasksAddList({commit, state}, payload) {
      let list = payload.list;
      commit('ADD_LIST', {list});
    },

    tasksAddBoard({commit, state}, payload) {
      let board = payload;
      commit('ADD_BOARD', {board});
    },
    tasksDeleteBoard({commit, state}) {
      commit('DELETE_BOARD');
    },
  },
  namespaced: true,
}
