import paginator from "@/helpers/paginator";

export default {

  grabAllPages: async (data = null) => await paginator.grabAllPages($nuxt.$api.host + '/introducer', {}, data),

  getIntroducers: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/introducer', data),
  createIntroducer: (introducer) => $nuxt.$axios.post($nuxt.$api.host + '/introducer', introducer),
  getIntroducer: (introducer_uuid, data) => $nuxt.$axios.get($nuxt.$api.host + '/introducer/' + introducer_uuid, data),
  updateIntroducer: (introducer_uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/introducer/' + introducer_uuid, data),
  deleteIntroducer: (introducer_uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/introducer/' + introducer_uuid),

}
