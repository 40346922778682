var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ui-sidebar-modal', {
    ref: "modal",
    attrs: {
      "has_header": true,
      "has_icon": ""
    },
    scopedSlots: _vm._u([{
      key: "header_title",
      fn: function () {
        return [_vm._v("\n    Add new note\n  ")];
      },
      proxy: true
    }, {
      key: "header_icon",
      fn: function () {
        return [_c('ui-icon-edit', {
          staticClass: "w-12 h-12 text-p-gray-7",
          attrs: {
            "type": "outline"
          }
        })];
      },
      proxy: true
    }, {
      key: "primary_button",
      fn: function () {
        return [_c('ui-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.addNote
          }
        }, [_vm._v("Post")])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _vm._v(" "), _c('div', {
    staticClass: "space-y-10"
  }, [_c('client-selector', {
    attrs: {
      "id": "clientSelector"
    },
    model: {
      value: _vm.client.uuid,
      callback: function ($$v) {
        _vm.$set(_vm.client, "uuid", $$v);
      },
      expression: "client.uuid"
    }
  }), _vm._v(" "), _vm.client.uuid !== null ? _c('div', [_c('ui-form-field', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("What would you like to add this note to?")];
      },
      proxy: true
    }], null, false, 2248782964)
  }, [_vm._v(" "), _c('button-choice', {
    attrs: {
      "options": _vm.modelableType
    },
    model: {
      value: _vm.linkTo,
      callback: function ($$v) {
        _vm.linkTo = $$v;
      },
      expression: "linkTo"
    }
  })], 1), _vm._v(" "), _vm.linkTo === 'case' ? _c('div', {
    staticClass: "space-y-6"
  }, [_c('client-case-selector', {
    attrs: {
      "client_uuid": _vm.client.uuid
    },
    model: {
      value: _vm.form.case_uuid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "case_uuid", $$v);
      },
      expression: "form.case_uuid"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.linkTo === 'task' ? _c('div', {
    staticClass: "space-y-6"
  }, [_c('client-task-select', {
    attrs: {
      "board-uuid": _vm.client.task_board_uuid
    },
    model: {
      value: _vm.form.task_uuid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "task_uuid", $$v);
      },
      expression: "form.task_uuid"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.linkTo === 'plan' ? _c('div', {
    staticClass: "space-y-6"
  }, [_c('client-plan-select', {
    attrs: {
      "client_uuid": _vm.client.uuid
    },
    model: {
      value: _vm.form.plan_uuid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "plan_uuid", $$v);
      },
      expression: "form.plan_uuid"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('ui-form-field', {
    staticClass: "flex flex-row mb-3 flex flex-col pt-4",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("Type your note below")];
      },
      proxy: true
    }], null, false, 1812082257)
  }, [_vm._v(" "), _c('redactor-input', {
    ref: "redactor",
    staticClass: "border-p-gray-6 border-p-pink bg-p-gray-8 rounded-xl border",
    attrs: {
      "model_type": _vm.noteable.type,
      "model_uuid": _vm.noteable.uuid
    },
    model: {
      value: _vm.form.contents,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contents", $$v);
      },
      expression: "form.contents"
    }
  }), _vm._v(" "), _c('p', {
    staticClass: "text-p-gray italic pt-1 text-xs"
  }, [_vm._v("Hint: mention someone using the @ symbol!")])], 1)], 1) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }